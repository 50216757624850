import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {AlertService} from '../../../shared-services/alert.service';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {FahrplanService} from '../../../api/api-services/fahrplan.service';
import {AdresseDddw} from '../../../api/api-dtos/adresse-dddw';
import {IFahrplan} from '../../../api/api-dtos/fahrplan';
import {GepaeckBeleg} from '../../../api/api-dtos/gepaeck-beleg';
import {formatDate, Location} from '@angular/common';
import {
  AccountingService,
  GepaeckBelegService,
  paymentTypesEnum,
  SmsService,
  StreckeSettingService
} from '../../../api/api-services/api-services.module';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DxFormComponent} from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import {Subject} from 'rxjs/internal/Subject';
import {ServerMessage} from '../../../api/api-dtos/server-message';
import {SmsTanType} from '../../../api/api-services/sms.service';
import {ITransportArt} from '../../../api/api-dtos/transport-art';
import {IStreckeSetting} from '../../../api/api-dtos/strecke-setting';
import {environment} from '../../../../environments/environment';
import {ZahlKontoArt} from '../../../api/api-dtos/zahl-konto-art-dto';
import {DxiItemComponent} from 'devextreme-angular/ui/nested';
import {PaymentPayOneService} from '../../../api/api-services/payment-pay-one.service';
import {ColumnValueFilter} from '../../../api/api-dtos/column-value-filter';
import {PaymentLog} from '../../../api/api-dtos/payment-log';
import {map, takeUntil, takeWhile} from 'rxjs/operators';
import {timer} from 'rxjs/internal/observable/timer';

declare let paypal: any;

@Component({
  selector: 'app-gepaeck-auftrag',
  templateUrl: './gepaeck-auftrag-edit.component.html',
  styleUrls: ['./gepaeck-auftrag-edit.component.scss']
})

export class GepaeckAuftragEditComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  componentTitle = 'Gepäckauftrag (Gastzugang)';
  public targetKey: string = environment.targetKey;
  public frontendGepaeckTransportBedingungenUrl: string;
  public maxCardWidth = 'auto';
  initialLoading = true;
  popoverVisible = false;
  submitOrderDisabled = new BehaviorSubject<boolean>(true);
  isValidOrderData = false;
  isProcessing = false;
  public isLoading = true;
  startDate: Date = new Date();
  public bruttoPreisProGepaeckStueck: number = 0;
  gepaeckAdresseDddw: AdresseDddw[] = [{}];
  gepaeckTransportArts: ITransportArt[];
  streckeSettings: IStreckeSetting[];
  currentStreckeSetting: IStreckeSetting;
  fahrplans: IFahrplan[];
  colCountByScreen: Object;
  gepaeckBeleg: GepaeckBeleg = this.gepaeckBelegService.getNewGepaeckBeleg();
  abFrachtortId: number;
  radioGroupItemsTypeOfTransport: any;
  radioGroupItemsFrachtOrte: any;
  public isPaymentCashInAdvancePermitted = true;
  public isMobileTanGenerated = false;
  public isDisableEditingData = false;
  public isDisableEditingPaymentType = true;
  public isPaymentTypeSelected = false;
  public isDisableEditingFaehre = true;
  public isGetMobileTanButtonDisabled = true;
  public isEditMobileTanDisabled = true;
  public isPayPalButtonsDisabled = true;
  public isPayOneButtonsDisabled = true;
  public isPayOnePopupVisible = false;
  public isPayOnePopupShowCancelButton = true;
  public payOneCurrentStatusText: string = 'offen';
  public isCheckDataAndGetApprovalCodeDisabled = false;
  public isSubmitOrderDataAndCashInAdvanceDisabled = true;
  public isSubmitOrderDataAndPayPalDisabled = true;
  public isSubmitOrderDataAndPayPalVisible = false;
  public isSubmitOrderDataAndPayOneDisabled = true;
  public isSubmitOrderDataAndPayOneVisible = false;
  // Payment, PayPal, PayOne
  public paymentTypeDddw: ZahlKontoArt[] = [{}];
  public paymentType: paymentTypesEnum | undefined = undefined;
  public paymentTypeByPayPal = paymentTypesEnum.payPal;
  public paymentTypeByPayOne = paymentTypesEnum.payOne;
  public paymentTypeByBankTransfer = paymentTypesEnum.bankTransfer;
  public paymentTypeByCash = paymentTypesEnum.cash;
  paidByPayPal = false;
  public gepaeckDescriptions: string[] = [];
  public valdiationDatumMessage: string = 'Bitte ein gültiges Datum wählen.';
  public paymentPayOnePayLinkUrl: string = null;
  notifyWidth: number = 400;
  newBrowserWindow: any;
  buttonCheckDataAndGetApprovalCode: any = {
    text: '1. Daten prüfen & mobile TAN anfordern',
    type: 'default',
    disabled: this.isCheckDataAndGetApprovalCodeDisabled,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    hint: 'Sendet nach erfolgreicher Prüfung der Daten eine mobile Transaktionsnummer (TAN) an Ihr mobiles Endgerät, mit der Sie diese Buchung im nächsten Schritt bestätigen und freigeben können.',
    onClick: (e: any) => {
      this.checkDataAndGetTan().then(() => {
        return;
      });
    },
  };
  buttonSubmitOrderDataAndCashInAdvance: any = {
    text: '2. Gepäckauftrag buchen + Überweisung',
    type: 'default',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    hint: 'Sendet Ihren Gepäckauftrag an das Buchungssystem.',
    onClick: () => {
      this.isPayPalButtonsDisabled = true;
      this.isDisableEditingPaymentType = true;
      this.paymentType = paymentTypesEnum.bankTransfer;
      this.onSubmitOrderData();
    },
  };
  buttonSubmitOrderDataAndPayPal: any = {
    text: 'Gepäckauftrag buchen + PayPal-Zahlung',
    type: 'default',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    hint: 'Sendet Ihren Gepäckauftrag an das Buchungssystem und verbindet zu PayPal.',
    onClick: () => {
      this.isDisableEditingPaymentType = true;
      this.isPayPalButtonsDisabled = false;
      this.paymentType = paymentTypesEnum.payPal;
      this.onSubmitOrderData();
    },
  };
  buttonSubmitOrderDataAndPayOne: any = {
    text: 'Gepäckauftrag buchen + bezahlen (PayPal, ...)',
    type: 'default',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    hint: 'Sendet Ihren Gepäckauftrag an das Buchungssystem und verbindet zur Online-Zahlung (PayPal, ...)',
    onClick: () => {
      this.isDisableEditingPaymentType = true;
      this.isPayOneButtonsDisabled = false;
      this.paymentType = paymentTypesEnum.payOne;
      this.onSubmitOrderData();
    },
  };
  buttonPayCreatedOrder: any = {
    text: 'Gepäckauftrag bezahlen (PayPal, ...)',
    type: 'default',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    hint: 'Leitet bereits gebuchten Auftrag weiter zur Online-Zahlung (PayPal, ...)',
    onClick: () => {
      this.isPayOneButtonsDisabled = false;
      this.isDisableEditingPaymentType = true;
      this.isPayPalButtonsDisabled = false;
      // this.paymentType = this.gepaeckBeleg.gpblg_zktoart_id;
      this.onSubmitOrderData();
    },
  };
  @ViewChild(DxFormComponent) gepaeckForm: DxFormComponent;
  @ViewChild('formcontainer') formContainer: ElementRef;
  @ViewChild('buttonCheckData', {static: false}) buttonCheckData: DxiItemComponent;
  @ViewChild('buttonGoToPayPal', {static: false}) buttonGoToPayPal: DxiItemComponent;
  @ViewChild('buttonGoToPayOne', {static: false}) buttonGoToPayOne: DxiItemComponent;
  @ViewChild('buttonGoToPayCreatedOrder', {static: false}) buttonGoToPayCreatedOrder: DxiItemComponent;
  @ViewChild('gpblgExternMobileTan', {static: false}) gpblgExternMobileTan: DxiItemComponent;
  @Input() inputGepaeckTAN: number | undefined;
  @Input() inputGepaeckID: number | undefined;
  @Input() inputFahrplanID: number | undefined;
  @Input() inputTypeOfTransport: string | undefined;
  protected readonly environment = environment;
  private isAlive: boolean;
  private unsubscribe = new Subject<void>();
  $paymentLookupInterval = timer(500, 2000)
    .pipe(takeWhile(() => this.isAlive))
    .pipe(takeUntil(this.unsubscribe));
  private history: string[] = [];
  private isValidPhoneNumber = false;
  private streckeSettingIndex: number;
  private _gepaeckStueckValue: any;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private adresseService: AdresseService,
    private fahrplanService: FahrplanService,
    private streckeSettingService: StreckeSettingService,
    private smsService: SmsService,
    private gepaeckBelegService: GepaeckBelegService,
    private location: Location,
    private accountingService: AccountingService,
    private paymentPayOneService: PaymentPayOneService
  ) {
    this.gepaeckBeleg = this.gepaeckBelegService.getNewGepaeckBeleg();

    this.checkDataAndGetTan = this.checkDataAndGetTan.bind(this);
    this.getIsValidPhoneNumber = this.getIsValidPhoneNumber.bind(this);
    this.getIsValidOrderData = this.getIsValidOrderData.bind(this);
    this.getIsEditMobileTanDisabled = this.getIsEditMobileTanDisabled.bind(this);
    this.getIsPayPalButtonsDisabled = this.getIsPayPalButtonsDisabled.bind(this);
    this.onGepaeckStueckTagCreatingDescriptions = this.onGepaeckStueckTagCreatingDescriptions.bind(this);
    this.onGepaeckStueckTagInput = this.onGepaeckStueckTagInput.bind(this);
    this.onGepaeckStueckTagKeyDown = this.onGepaeckStueckTagKeyDown.bind(this);
    this.onGepaeckStueckTagFocusOut = this.onGepaeckStueckTagFocusOut.bind(this);
    this.getMaxCardWidth = this.getMaxCardWidth.bind(this);
    this.asyncValidationGpBlgAnz = this.asyncValidationGpBlgAnz.bind(this);
    this.asyncValidationDatum = this.asyncValidationDatum.bind(this);
    this.getDatumLabelText = this.getDatumLabelText.bind(this);
    this.getFahrplanLabelText = this.getFahrplanLabelText.bind(this);
    this.paymentTypeChanged = this.paymentTypeChanged.bind(this);
    this.payByPayOne = this.payByPayOne.bind(this);
    this.gepaeckBelegService.signAsUnPayed = this.gepaeckBelegService.signAsUnPayed.bind(this);
    this.isLoading = true;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
    // this.gepaeckBeleg = this.gepaeckBelegService.getNewGepaeckBeleg();

    switch (environment.targetKey) {
      case '10477':
        this.isPaymentCashInAdvancePermitted = false;
    }

    this.gepaeckBelegService.getBruttoPreisProGepaeckStueck().then(resPreis => {
      this.bruttoPreisProGepaeckStueck = resPreis;
      if (!environment.production) console.log('constructor getBruttoPreisProGepaeckStueck: resPreis ', resPreis, this.bruttoPreisProGepaeckStueck);
    });

    this.accountingService.getZahlArtDddw().then(res => {
      this.paymentTypeDddw = this.accountingService.removeZahlArtFromDddw(res, 'MDSI');
      switch (environment.targetKey) {
        case '10426':
          this.paymentTypeDddw = this.accountingService.removeZahlArtFromDddw(this.paymentTypeDddw, 'PAYONE');
          break;
        case '10477':
          this.paymentTypeDddw = this.accountingService.removeZahlArtFromDddw(this.paymentTypeDddw, 'KASSE');
          this.paymentTypeDddw = this.accountingService.removeZahlArtFromDddw(this.paymentTypeDddw, 'BANK');
          this.paymentTypeDddw = this.accountingService.removeZahlArtFromDddw(this.paymentTypeDddw, 'BANK-ON');
          this.paymentTypeDddw = this.accountingService.removeZahlArtFromDddw(this.paymentTypeDddw, 'PAYPAL');
          break;
        default:
          break;
      }
      if (this.paymentTypeDddw !== undefined && this.paymentTypeDddw.length === 1) {
        this.gepaeckBeleg.gpblg_zktoart_id = this.paymentTypeDddw[0].zktoart_id;
        this.paymentType = this.gepaeckBeleg.gpblg_zktoart_id;
        this.paymentTypeChanged({'value': this.paymentTypeDddw[0].zktoart_id});
        // this.scrollPageToTop()
      }
    });

    this.inputGepaeckID = 0;
    this.gepaeckTransportArts = [];
    this.streckeSettings = [];
    this.streckeSettingService.getTransportArtListByService('gepaeck')
      .subscribe(resTransport => {
        // FLUG, FAEHRE, ...
        this.gepaeckTransportArts = resTransport;
        this.radioGroupItemsTypeOfTransport = this.streckeSettingService.getGroupItemsTypeOfTransport(resTransport);
      });
    this.inputFahrplanID = null;

    route.params.subscribe(params => {
      if (!environment.production) console.log('paramiso', params);
      this.inputFahrplanID = !!params['fp_id'] ? params['fp_id'] : null;
      this.inputTypeOfTransport = !!params['fp_typeOfTransport'] ? params['fp_typeOfTransport'].toString().toUpperCase() : null;
      this.inputGepaeckID = !!params['id'] ? params['id'] : null;
      this.inputGepaeckTAN = !!params['tan'] ? params['tan'] : null;

      if (!environment.production) console.log('constr 269 ', this.inputFahrplanID, this.inputTypeOfTransport, this.inputGepaeckID, this.inputGepaeckTAN);

      if (!!this.inputTypeOfTransport) {
        this.gepaeckBeleg.gpblg_dummy_fp_art_kz = this.inputTypeOfTransport.toUpperCase();
        if (!environment.production) console.log('constr 273 inputTypeOfTransport vorhanden ', this.gepaeckBeleg.gpblg_dummy_fp_art_kz);
        this.switchTransportArtCode(this.gepaeckBeleg.gpblg_dummy_fp_art_kz).then(() => {
          if (!environment.production) console.log('constr 275 nach switchTransportArtCode ', this.gepaeckBeleg.gpblg_dummy_fp_art_kz);
          this.doInit();
        });
      } else if (this.gepaeckTransportArts.length > 0) {
        if (!environment.production) console.log('constr 278  OHNE inputTypeOfTransport ', this.gepaeckBeleg.gpblg_dummy_fp_art_kz);
        this.gepaeckBeleg.gpblg_dummy_fp_art_kz = this.gepaeckTransportArts[0].transportArtCode;
        this.switchTransportArtCode(this.gepaeckBeleg.gpblg_dummy_fp_art_kz).then(() => {
          this.doInit();
        });
      }
    });

    if ((this.inputFahrplanID == null || this.inputFahrplanID <= 1) && !(!!this.inputTypeOfTransport)) {
      this.gepaeckBeleg.gpblg_dummy_fp_art_kz = 'FAEHRE';
      if (this.gepaeckTransportArts !== undefined && this.gepaeckTransportArts.length > 0) {
        this.radioGroupItemsTypeOfTransport = this.streckeSettingService.getGroupItemsTypeOfTransport(this.gepaeckTransportArts);
        this.gepaeckBeleg.gpblg_dummy_fp_art_kz = this.gepaeckTransportArts[0].transportArtCode;
        this.switchTransportArtCode(this.gepaeckBeleg.gpblg_dummy_fp_art_kz).then();
      }
      this.doInit();
    }
    this.initialLoading = false;
  }

  ngOnDestroy(): void {
    // Emit something to stop all Observables
    this.unsubscribe.next();
    // Complete the notifying Observable to remove it
    this.unsubscribe.complete();
    this.isAlive = false;
  }

  doResize(): void {
    if (window.innerWidth < 300) {
      this.maxCardWidth = '300px';
    } else if (window.innerWidth <= 640) {
      this.maxCardWidth = (window.innerWidth - 40).toString() + 'px';
    } else {
      this.maxCardWidth = '620px';
    }
  }

  ngOnChanges(changes: any): void {
    if (!environment.production) console.log('ngOnChanges START ');
    this.isLoading = true;
    if (this.bruttoPreisProGepaeckStueck === undefined || this.bruttoPreisProGepaeckStueck <= 0) {
      this.gepaeckBelegService.getBruttoPreisProGepaeckStueck().then(resPreis => {
        this.bruttoPreisProGepaeckStueck = resPreis;
        if (!environment.production) console.log('ngOnChanges START this.bruttoPreisProGepaeckStueck ', this.bruttoPreisProGepaeckStueck);
      });
    }

    if (!!this.inputGepaeckID) {
      this.gepaeckBelegService.getGepaeckBeleg(this.inputGepaeckID, null).subscribe((resGepBeleg: GepaeckBeleg) => {
        if (resGepBeleg === undefined) {
          this.isLoading = false;
          return;
        }
        this.gepaeckBeleg = resGepBeleg;
        this.isLoading = false;
        return;
      });
    }
  }

  async ngOnInit() {
    this.isAlive = true;
    this.gepaeckDescriptions = this.gepaeckBelegService.getGepaeckDescriptions();
    this.frontendGepaeckTransportBedingungenUrl = this.gepaeckBelegService.loadFrontendTransportBedingungenUrl();

    if (!environment.production) console.log('ngOnInit fp_id ', this.gepaeckBeleg.fp_id);

    if (this.maxCardWidth === 'auto') {
      this.doResize();
    }
    window.onresize = () => {
      this.doResize();
    };

    if (environment.targetKey == '10426') {
      if (paypal === undefined) {
        notify({
          message: 'Die Verbindung zu PayPal kann gerade nicht geladen werden. ' +
            'Bitte versuchen Sie es nochmals oder kontaktieren Sie uns. ',
          width: this.notifyWidth,
          position: {
            my: 'center',
            at: 'center'
          }
        }, 'warning', 6000);
      } else {

        paypal
          .Buttons({
            style: {
              shape: 'rect',
              layout: 'vertical',
              color: 'silver',
              label: 'paypal'
            },
            createOrder: (data: any, actions: any) => {
              return actions.order.create({
                intent: 'CAPTURE',
                purchase_units: [
                  {
                    reference_id: 'GEP-' + this.gepaeckBeleg.gpblg_id.toString(),
                    description: 'Gepäcktransport',
                    custom_id: this.gepaeckBeleg.gpblg_rgempf_adr_id?.toString() ?? 'GAST',
                    amount: {
                      currency_code: 'EUR',
                      value: this.gepaeckBeleg.gpblg_brutto_amount.toFixed(2).toString(),
                      breakdown: {
                        item_total: {
                          currency_code: 'EUR',
                          value: this.gepaeckBeleg.gpblg_brutto_amount.toString()
                        }
                      }
                    },
                    items: [
                      {
                        name: 'Gepäckstücke / Gepäckauftrag GEP-' + this.gepaeckBeleg.gpblg_id.toString(),
                        description: 'Transport von Gepäck auf der Insel von oder zu einer Inseladresse. Details: siehe SMS und. ggf. E-Mail.',
                        category: 'DIGITAL_GOODS',
                        unit_amount: {
                          currency_code: 'EUR',
                          value: this.bruttoPreisProGepaeckStueck.toString()
                        },
                        quantity: this.gepaeckBeleg.gpblg_anz.toString()
                      }]
                  }
                ]
              });
            },
            onApprove: async (data: any, actions: any) => {
              const order = await actions.order.capture();
              if (order.status === 'COMPLETED') {
                // todo: zumindest order.id oder besser das ganze Object merken
                this.paidByPayPal = true;
                // todo: Das sollte besser der Server machen
                this.gepaeckBelegService.signAsPayedByPayPal(this.gepaeckBeleg.gpblg_id,
                  this.gepaeckBeleg.gpblg_extern_mobiletan,
                  order.id,
                  order.update_time,
                  order)
                  .then(() => {
                    notify({
                      message: 'Die Zahlung war erfolgreich. Wir aktivieren nun Ihren Auftrag. ' +
                        'Siehe auch Link zum Auftrag in SMS und/oder E-Mail.',
                      width: this.notifyWidth,
                      position: {
                        my: 'center',
                        at: 'center'
                      }
                    }, 'success', 4000);
                    this.isDisableEditingData = true;
                    this.router.navigate(['/gepaeckauftrag/show', this.gepaeckBeleg.gpblg_id, this.gepaeckBeleg.gpblg_extern_mobiletan]);
                  }).then(() => {
                  // NACH PayPal-Zahlung: jetzt Mail und SMS versenden
                  this.gepaeckBelegService.notifyOrderToCustomer(this.gepaeckBeleg.gpblg_id,
                    this.gepaeckBeleg.gpblg_extern_mobiletan);
                });
              } else {
                if (!this.environment.production) console.log('PayPal transaction aborted', order);
                notify({
                  message: 'Die Zahlung war NICHT erfolgreich oder wurde abgebrochen. Bitte versuchen Sie es nochmals. ',
                  width: this.notifyWidth,
                  position: {
                    my: 'center',
                    at: 'center'
                  }
                }, 'warning', 6000);
                this.isDisableEditingPaymentType = false;
                this.paidByPayPal = false;
              }
            },
            onCancel: async (data: any) => {
              if (!this.environment.production) console.log('PayPal cancel event triggered', data);
              notify({
                message: 'Die Zahlung wurde abgebrochen. Bitte versuchen Sie es nochmals. ',
                width: this.notifyWidth,
                position: {
                  my: 'center',
                  at: 'center'
                }
              }, 'warning', 6000);
              this.isDisableEditingPaymentType = false;
              this.paidByPayPal = false;
            },
            onError: (err: any) => {
              if (!this.environment.production) console.log('PayPal onError event', err);
              let message: string = 'Fehler beim Aufruf von PayPal.';
              const jsonMatch = err?.toString().match(/\{.*\}/);

              if (jsonMatch) {
                try {
                  // Parsing des JSON-Strings in ein Objekt
                  const errorJson = JSON.parse(jsonMatch[0]);
                  console.log(errorJson);

                  switch (errorJson?.name?.toString()) {
                    case 'UNPROCESSABLE_ENTITY':
                      message += 'Ursache: Daten können nicht verarbeitet werden. ';
                      break;
                    default:
                      message += 'Ursache:' + errorJson?.name?.toString() + ' ';
                  }

                  switch (errorJson?.details[0]?.issue?.toString()) {
                    case 'PAYEE_ACCOUNT_RESTRICTED':
                      message += 'Hinweis: Das Empfängerkonto ist beschränkt. ';
                      break;
                    default:
                      message += 'Hinweis:' + errorJson?.details[0]?.issue?.toString() + ' ';
                  }
                  message += !!err?.details[0]?.description?.toString() ? '' + err?.details[0]?.description?.toString() + ' ' : '';

                } catch (error) {
                  console.error('PayPal onError event, Fehler beim Parsen des JSON: ', err);
                }
              }
              message += 'Ggf. eine andere Zahlungsart wählen und verwenden. ';

              notify({
                message: message,
                width: this.notifyWidth,
                position: {
                  my: 'center',
                  at: 'center'
                }
              }, 'warning', 8000);
              this.isProcessing = false;
              this.isDisableEditingPaymentType = false;
            }
          }).render('#paypalButtons');
      }
    }
  }

  ngAfterViewInit(): void {
    if (!environment.production) console.log('ngAfterViewInit fp_id ', this.gepaeckBeleg.fp_id);
    setTimeout(() => {
      if (this.gepaeckBeleg?.gpblg_id !== undefined && this.gepaeckBeleg?.gpblg_id > 0) {
        this.gepaeckForm?.instance?.validate();
      }
    }, 1000);
  }

  public async lookupFahrplans() {
    this.fahrplans = [{} as IFahrplan];
    if (!this.gepaeckBeleg) {
      return;
    }
    let searchDate: Date = this.gepaeckBeleg.gpblg_dummy_datum;
    if (this.startDate !== undefined && this.startDate !== null) {
      if (new Date(searchDate).setHours(0, 0, 0, 0) === new Date(this.startDate).setHours(0, 0, 0, 0) && this.startDate > searchDate) {
        searchDate = this.startDate;
      }
    }
    await this.fahrplanService.getGepaeckServiceFahrtenByAbfahrtOrt(
      searchDate,
      this.gepaeckBeleg.gpblg_dummy_abgang_fort_id).then((resFahrplan) => {
      this.fahrplans = resFahrplan;
      if (!this.fahrplans || this.fahrplans.length < 1) {
        this.gepaeckBeleg.fp_id = null;
        return;
      }
      if (!!this.fahrplans && this.fahrplans.length === 1 && !!this.fahrplans[0].fp_id) {
        this.gepaeckBeleg.fp_id = this.fahrplans[0].fp_id;
      } else {
        if (this.gepaeckBeleg.fp_id !== 0 && this.fahrplans.find(e => e.fp_id == this.gepaeckBeleg.fp_id)) {
          // selektiere aus der Liste der Fahrten die bereits gewählte Fahrt!
          this.gepaeckBeleg.fp_id = this.fahrplans[(this.fahrplans.findIndex(e => e.fp_id == this.gepaeckBeleg.fp_id))].fp_id;
        } else {
          this.gepaeckBeleg.fp_id = this.fahrplans[0].fp_id;
        }
      }
    });
  }

  async checkDataAndGetTan() {
    // let mobileTan: string;
    if (!environment.production) console.log('checkDataAndGetTan Start');
    this.isGetMobileTanButtonDisabled = true;
    if (this.isDisableEditingData) {
      notify({
        message: 'Für einen bereits gebuchten Auftrag kann keine weitere TAN angefordert werden.',
        width: this.notifyWidth,
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'warning', 2000);
      return;
    }

    this.isProcessing = true;
    if (this.getIsValidOrderData()) {
      this.scrollPageToBottom();
      notify({
        message: 'Eingaben erfolgreich geprüft. TAN wird an Ihr Mobiltelefon gesendet.',
        width: this.notifyWidth,
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'success', 1500);
      // remove blanks ...
      this.gepaeckBeleg.gpblg_extern_mobil_tel = this.gepaeckBeleg.gpblg_extern_mobil_tel.trim();
      await this.smsService.sendGeneratedMobileTanToUserPhoneWithPurpose(this.gepaeckBeleg.gpblg_extern_mobil_tel, SmsTanType.GepaeckBeleg)
        .then((resSmsResponse: ServerMessage) => {
          if (resSmsResponse?.status === undefined || resSmsResponse?.status?.valueOf() !== 'success') {
            this.isMobileTanGenerated = false;
            this.isGetMobileTanButtonDisabled = false;
            this.isEditMobileTanDisabled = true;
            this.gepaeckBeleg.gpblg_extern_mobiletan = null;
          } else {
            this.isMobileTanGenerated = true;
            this.isEditMobileTanDisabled = false;
            this.isGetMobileTanButtonDisabled = false;
            // Data ok, TAN ok, -> ready to complete transaction
            this.submitOrderDisabled.next(false);
            this.isDisableEditingData = true;
            this.isDisableEditingPaymentType = false;
          }
          this.isProcessing = false;
        });
    }
    this.gpblgExternMobileTan.instance.focus();
    this.scrollPageToBottom();
    this.isProcessing = false;

    return;
  }

  form_fieldDataChanged(e: any) {
    if (this.isLoading || this.isDisableEditingData) {
      this.isLoading = false;
      return;
    }
    if (e.component.option('formData') === undefined || e.component.option('formData') === null) {
      return;
    }
    this.gepaeckBeleg = e.component.option('formData');
    if (this.gepaeckBeleg === undefined) {
      this.isDisableEditingFaehre = true;
      return;
    }
    switch (e.dataField) {
      case 'gpblg_dummy_fp_art_kz':
        this.switchTransportArtCode(e.value).then(() => {
          this.lookupFahrplans().then(() => {
            return;
          });
        });
        break;

      case 'gpblg_dummy_abgang_fort_id':
        if (e.value == null || this.currentStreckeSetting == undefined || e.value == this.currentStreckeSetting.svstrs_ort1_fort_id) {
          return;
        }
        this.gepaeckBeleg.gpblg_dummy_ziel_fort_id = this.currentStreckeSetting.svstrs_ort1_fort_id;
        this.reselectStreckeSettingAfterRichtungChange(this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);

        if (new Date(this.startDate).setHours(0, 0, 0, 0) > new Date(this.gepaeckBeleg.gpblg_dummy_datum).setHours(0, 0, 0, 0)) {
          this.gepaeckBeleg.gpblg_dummy_datum = new Date(new Date(this.startDate).setHours(0, 0, 0, 0));
        }

        this.lookupFahrplans().then(() => {
          return;
        });
        break;
      case 'gpblg_dummy_datum':
        this.gepaeckBeleg.gpblg_dummy_datum = e.value;
        this.lookupFahrplans().then(() => {
          return;
        });
        break;
      case 'fp_id':
        this.getDisplayAbfahrtzeitVoll(e.dataField.item);
        break;
      case 'gpblg_extern_mobil_tel':
        if (e.value === null || e.value.toString().trim() === '') {
          break;
        } else {
          if (e.value.substring(0, 4) === '0049') {
            this.gepaeckBeleg.gpblg_extern_mobil_tel = '+49' + e.value.substring(4, 99);
          }
        }
        this.smsService.callPhoneNumberValidator(e.value).then(resSmsPhoneFormat => {
          if (!!resSmsPhoneFormat && resSmsPhoneFormat?.success === true) {
            this.isValidPhoneNumber = true;
            this.gepaeckBeleg.gpblg_extern_mobil_tel = resSmsPhoneFormat.international_formatted;
          } else {
            this.isValidPhoneNumber = false;
          }
          this.getIsValidOrderData();
        });
        break;
      case 'gpblg_anz':
        if (e.value === null || e.value === 0) {
          this.gepaeckBeleg.gpblg_brutto_amount = 0;
          break;
        } else {
          this.gepaeckBeleg.gpblg_brutto_amount = Number((e.value * this.bruttoPreisProGepaeckStueck).toFixed(2));
        }
        break;
      case 'adr_id':
        // GEP-Anzahl, ... erneut validieren ...
        if (!!this.gepaeckBeleg.gpblg_anz && this.gepaeckBeleg.gpblg_anz > 0) {
          this.getIsValidOrderData();
        }
        break;
      /*case 'gpblg_zktoart_id':
        this.paymentTypeChanged(e);
        break;*/
    }
    this.isDisableEditingFaehre = this.isDisableEditingData || this.gepaeckBeleg.gpblg_dummy_abgang_fort_id === undefined;
  }

  getDisplayAbfahrtzeit(item: any): string {
    if (!item || !item.fp_abfahrt_datzeit) {
      return '';
    }
    return formatDate(item.fp_abfahrt_datzeit, 'HH:mm', 'de-DE');
  }

  getDisplayAbfahrtzeitVoll(item: IFahrplan): string {
    if (!item || !item.fp_abfahrt_datzeit) {
      return '';
    }
    if (parent.window.outerWidth < 520) {
      return formatDate(item.fp_abfahrt_datzeit, 'EE dd.MM.yy HH:mm', 'de-DE');
    } else {
      return formatDate(item.fp_abfahrt_datzeit, 'EE dd.MM.yy HH:mm', 'de-DE') + ' ' + item.ger_name;
    }
  }

  async prepareEditing() {
    if (this.gepaeckAdresseDddw === undefined || this.gepaeckAdresseDddw.length <= 1) {
      this.adresseService.getGepaeckAdrDddwNow().subscribe(res => {
        this.gepaeckAdresseDddw = res;
        return;
      });
    }
  }

  checkComparison() {
    return true;
  }

  getIsValidOrderData(): boolean {
    this.isValidOrderData = this.gepaeckForm.instance.validate().isValid;
    // this.buttonCheckDataAndGetApprovalCode.disabled = !this.isValidOrderData;
    return this.gepaeckForm.instance.validate().isValid;
  }

  onSubmitOrderData() {
    if (!environment.production) console.log('onSubmitOrderData START before isValid ', this.gepaeckBeleg);
    let insertMode: boolean = false;

    if (!this.gepaeckBeleg.gpblg_zktoart_id || this.gepaeckBeleg.gpblg_zktoart_id !== this.paymentType) {
      this.gepaeckBeleg.gpblg_zktoart_id = this.paymentType;
    }

    if (this.paymentType === paymentTypesEnum.payPal) {
      this.gepaeckBeleg.gpblg_bezahlt_provider_name = 'PayPal';
    } else if (this.paymentType === paymentTypesEnum.payOne) {
      this.gepaeckBeleg.gpblg_bezahlt_provider_name = 'PayOne';
    } else {
      this.gepaeckBeleg.gpblg_bezahlt_provider_name = null;
    }

    // Normalfall: Auftrag ist noch nicht gespeichert ...
    if (this.gepaeckBeleg.gpblg_id === undefined || this.gepaeckBeleg.gpblg_id === null) {
      insertMode = true;
      if (!this.getIsValidOrderData()) {
        notify({
          message: 'Bitte erst die Daten vervollständigen, eine TAN anfordern und eintragen.',
          width: this.notifyWidth,
          position: {
            my: 'center',
            at: 'center'
          }
        }, 'error', 2000);
        this.isDisableEditingData = false;
        return;
      }

      if (!this.gepaeckBeleg?.gpblg_extern_mobiletan || this.gepaeckBeleg.gpblg_extern_mobiletan.toString().trim().length !== 6) {
        notify({
          message: 'Bitte erst die korrekte per SMS übermittelte TAN eintragen.',
          width: this.notifyWidth,
          position: {
            my: 'center',
            at: 'center'
          }
        }, 'error', 2000);
        this.isDisableEditingPaymentType = false;
        this.isPayPalButtonsDisabled = true;
        return;
      }

      // SAVE order
      // Gepaeckauftrag anlegen vorlaeufig anlegen (unbezahlt)
      // PayPal-Zahlung: erst danach: SMS und Mail senden, als bezahlt markieren
      this.gepaeckBelegService.create(this.gepaeckBeleg).then(res => {
        if (res && res.status) {
          switch (res.status) {
            case '200':
            case 'success':
              this.gepaeckBeleg = res;
              if (!environment.production) console.log('onSubmitOrderData Order SUCCESSfully created ', this.gepaeckBeleg);
              if (!this.gepaeckBeleg.gpblg_zktoart_id || this.gepaeckBeleg.gpblg_zktoart_id !== this.paymentType) {
                this.gepaeckBeleg.gpblg_zktoart_id = this.paymentType;
              }
              if (this.gepaeckBeleg.gpblg_id && this.gepaeckBeleg.gpblg_id > 0) {
                if (this.paymentType === paymentTypesEnum.bankTransfer || this.paymentType === paymentTypesEnum.cash) {
                  // notify SMS, MAIL ist bereits durch API erfolgt
                  notify({
                    message: 'Ihr Gepäckauftrag wurde gebucht und wird NACH erfolgter ZAHLUNG gültig. ' +
                      'Siehe Benachrichtigung per SMS und ggf. E-Mail.',
                    width: this.notifyWidth,
                    position: {
                      my: 'center',
                      at: 'center'
                    }
                  }, 'success', 4000);
                  this.isProcessing = false;
                  this.router.navigate(['/gepaeckauftrag/show', this.gepaeckBeleg.gpblg_id, this.gepaeckBeleg.gpblg_extern_mobiletan]).then();
                }
                if (!environment.production) console.log('onSubmitOrderData Order PayOne? ', this.paymentType === paymentTypesEnum.payOne, this.paymentPayOnePayLinkUrl);
                if (this.paymentType === paymentTypesEnum.payOne) {
                  this.isDisableEditingData = true;
                  // erneuter Aufruf nach vorherigem Abbruch: direkt zu PayOne ...
                  this.payByPayOne();
                }
              }
              return;
            case 'error':
              notify({
                message: 'Problem: Ihr Gepäckauftrag konnte NICHT erfolgreich gespeichert werden. '
                  + (!!res.message ? res.message : '') +
                  ' Bitte nochmals versuchen. ',
                width: this.notifyWidth,
                position: {
                  my: 'center',
                  at: 'center'
                }
              }, 'error', 4000);
              this.isDisableEditingPaymentType = false;
              if (this.paymentType === paymentTypesEnum.payPal) {
                this.isPayPalButtonsDisabled = true;
                this.scrollPageToBottom();
              }
              this.isProcessing = false;
              return;
            default:
          }
        }
      }).catch(error => {
        if (!environment.production) console.log('Error when calling gepaeckBelegService.create', this.gepaeckBeleg);

        notify({
          message: 'Problem: Ihr Gepäckauftrag konnte NICHT erfolgreich angelegt werden. ' +
            'Bitte nochmals versuchen. ' + error.message,
          width: this.notifyWidth,
          position: {
            my: 'center',
            at: 'center'
          }
        }, 'error', 6000);
        this.isDisableEditingData = false;
        if (this.paymentType === paymentTypesEnum.payPal) {
          this.isPayPalButtonsDisabled = true;
        }
        this.isProcessing = false;
      });
    }

    this.scrollPageToBottom();
    /*if (!!this.paymentPayOnePayLinkUrl) {
      this.isDisableEditingData = true;
      // erneuter Aufruf nach vorherigem Abbruch: direkt zu PayOne ...
      this.payByPayOne();
      return;
    }*/

    this.isDisableEditingPaymentType = true;
    this.isProcessing = true;

    // ggf. geänderte Zahlungsart speichern ...
    if (!insertMode) {
      this.gepaeckBelegService.update(this.gepaeckBeleg).then();
    }

    // Gepaeckauftrag anlegen vorlaeufig anlegen (unbezahlt)
    // PayPal-Zahlung: erst danach: SMS und Mail senden, als bezahlt markieren

    if (this.gepaeckBeleg.gpblg_id && this.gepaeckBeleg.gpblg_id > 0) {
      if (this.paymentType === paymentTypesEnum.bankTransfer || this.paymentType === paymentTypesEnum.cash) {
        this.gepaeckBelegService.signAsUnPayed(this.gepaeckBeleg.gpblg_id).then();
        this.gepaeckBelegService.notifyOrderToCustomer(this.gepaeckBeleg.gpblg_id, this.gepaeckBeleg.gpblg_extern_mobiletan).then();
        notify({
          message: 'Ihr Gepäckauftrag wurde gebucht und wird nach erfolgter Zahlung gültig. ' +
            'Siehe Benachrichtigung per SMS und ggf. E-Mail.',
          width: this.notifyWidth,
          position: {
            my: 'center',
            at: 'center'
          }
        }, 'success', 4000);
        this.isProcessing = false;
        this.router.navigate(['/gepaeckauftrag/show', this.gepaeckBeleg.gpblg_id, this.gepaeckBeleg.gpblg_extern_mobiletan]).then();
      } else if (this.paymentType === paymentTypesEnum.payPal) {
        // Beleg soll nicht als bezahlt gekennzeichnet sein! (ggf. API anpassen)
        this.gepaeckBelegService.signAsUnPayed(this.gepaeckBeleg.gpblg_id).then();
        this.isDisableEditingData = true;
        this.isProcessing = false;
        this.isSubmitOrderDataAndPayPalDisabled = true;
        this.isPayPalButtonsDisabled = false;
        this.scrollPageToBottom();

        notify({
          message: 'Jetzt bitte per PayPal bezahlen. ',
          width: this.notifyWidth,
          position: {
            my: 'center',
            at: 'center'
          }
        }, 'info', 2000);
        this.isProcessing = false;
      } else if (this.paymentType === paymentTypesEnum.payOne) {
        this.isDisableEditingData = true;
        this.isProcessing = false;
        this.isPayOneButtonsDisabled = false;
        this.isSubmitOrderDataAndPayPalDisabled = true;
        this.scrollPageToBottom();

        notify({
          message: 'Jetzt bitte per PayOne bezahlen. ',
          width: this.notifyWidth,
          position: {
            my: 'center',
            at: 'center'
          }
        }, 'info', 2000);
        this.isProcessing = false;
        this.buttonSubmitOrderDataAndPayOne.text = 'Gepäckauftrag bezahlen (PayPal, ...)';
        this.payByPayOne();
        return;
      }
    }
    return;
  }

  public payByPayOne() {
    let shopOrder = this.gepaeckBelegService.getShopOrder(this.gepaeckBeleg);
    if (!environment.production) console.log('payByPayOne', this.paymentPayOnePayLinkUrl, shopOrder);
    if (!!this.paymentPayOnePayLinkUrl) {
      // 20241011 this.isPayOnePopupVisible = true;
      this.goToPayOne();
      return;
    }
    this.paymentPayOneService.getPayLink(shopOrder).then(resPayLink => {
      if (!environment.production) console.log('getPayLink ', resPayLink);
      if (resPayLink == undefined || (resPayLink.link ?? '') === '') {
        this.paymentPayOnePayLinkUrl = null;
        notify({
          message: 'Problem: Verbindung zum Zahlungsdienstleister war nicht erfolgreich. ' +
            'Bitte nochmals versuchen. ',
          width: this.notifyWidth,
          position: {
            my: 'center',
            at: 'center'
          }
        }, 'error', 4000);
        this.isDisableEditingData = true;
        this.isDisableEditingPaymentType = false;
        this.isPayOneButtonsDisabled = true;
        this.isProcessing = false;
        return;
      }
      this.paymentPayOnePayLinkUrl = resPayLink.link;
      // 20241011 this.isPayOnePopupVisible = true;
      this.goToPayOne();
    }).catch(error => {
      if (!environment.production) console.log('PayOne PAYLINK: ', error.message);
      notify({
        message: 'Zahlung per PayOne/PayPal konnte NICHT erfolgreich gestartet werden. ',
        width: this.notifyWidth,
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'error', 6000);
      this.isDisableEditingData = true;
      this.isDisableEditingPaymentType = false;
      this.isProcessing = false;
      this.isPayOneButtonsDisabled = true;
      this.isProcessing = false;
    });
  }

  public goToPayOne() {
    const filters: ColumnValueFilter[] = [new ColumnValueFilter()];
    // if (!environment.production) console.log('goToPayOne', this.paymentPayOnePayLinkUrl);
    // var paymentLogData: PaymentLog[] = [];
    if (!!this.paymentPayOnePayLinkUrl) {
      this.payOneCurrentStatusText = 'PayOne starten';
      // im gleichen Fenster bleiben, sonst gibt es Probleme mit Apple / Safari!
      window.location.href = this.paymentPayOnePayLinkUrl;
      this.payOneCurrentStatusText = 'PayOne gestartet';
      filters[0] = {column: 'pylg_shoporder_guid', value: this.gepaeckBeleg.gpblg_shoporder_guid};
      this.isPayOnePopupVisible = true;
      this.monitorPayOne();
    }
  }

  public async asyncValidationGpBlgAnz(params: any): Promise<unknown> {
    if (params === undefined) {
      return;
    }
    let mindestanzahl: number = 1;

    if (!!this.gepaeckBeleg.adr_id && this.gepaeckBeleg.adr_id > 0) {
      // mindestanzahl = this.gepaeckBeleg.adr_id === 16592 ? 4: 1;
      let firstEntryMatchIndex = -1;
      if (!this.gepaeckAdresseDddw || this.gepaeckAdresseDddw.length < 1) {
        mindestanzahl = 1;
      } else {
        firstEntryMatchIndex = this.gepaeckAdresseDddw
          .findIndex((e) =>
            e.adr_id === this.gepaeckBeleg.adr_id);

        if (firstEntryMatchIndex > -1 && this.gepaeckAdresseDddw[firstEntryMatchIndex] && this.gepaeckAdresseDddw[firstEntryMatchIndex]?.adr_id && this.gepaeckAdresseDddw[firstEntryMatchIndex]?.adr_gepaeck_minimum_anz) {
          mindestanzahl = this.gepaeckAdresseDddw[firstEntryMatchIndex].adr_gepaeck_minimum_anz;
        }
      }
    }
    params.rule.message = 'Bitte die Mindestanzahl von ' + mindestanzahl.toString() + ' Gepäckstücken nicht unterschreiten.';
    return this.gepaeckBelegService.checkMindestGepaeckAnzahl(params.value, mindestanzahl);
  }

  public async asyncValidationDatum(params: any): Promise<unknown> {
    if (params === undefined || !this.startDate) {
      return;
    }

    params.rule.message = 'Bitte das Mindestdatum ' + this.startDate.toLocaleString() + ' nicht unterschreiten. Auf Grund der unterschiedlichen Abläufe sind die möglichen Servicezeiten zwischen An- und Abreise verschieden.';
    return this.gepaeckBelegService.checkMindestDatum(params.value, this.startDate);
  }

  public getDatumLabelText(): string {
    if (!this.startDate || !this.startDate == null) {
      return 'Datum';
    }
    if (this.startDate.getHours() + this.startDate.getMinutes() == 0) {
      return `Datum (ab ${this.startDate.toLocaleDateString('de-DE', {
        weekday: 'short',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      })})`;
    }
    return `Datum (ab ${this.startDate.toLocaleDateString('de-DE', {
      weekday: 'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })} ${this.startDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    })})`;
  }

  public getFahrplanLabelText(): string {
    if (this.gepaeckBeleg == undefined || !(!!this.gepaeckBeleg.gpblg_dummy_fp_art_kz) || this.gepaeckBeleg.gpblg_dummy_fp_art_kz === 'FAEHRE') {
      return 'Fähre (geplante Abfahrt)';
    }
    if (this.gepaeckBeleg.gpblg_dummy_fp_art_kz === 'FLUG') {
      return 'Flug (geplanter Abflug)';
    }
    return 'Fähre (geplante Abfahrt)';
  }

  public getIsValidPhoneNumber(e: any): boolean {
    if (e.value === null || e.value.trim() === '') {
      this.isValidPhoneNumber = false;
      return false;
    }
    return this.isValidPhoneNumber;
  }

  public isSubmitOrderDisabled(): boolean {
    return this.submitOrderDisabled.getValue();
  }

  public onGepaeckStueckTagCreatingDescriptions(args: any) {
    const newValue = args.text;
    if (!this.gepaeckDescriptions) {
      this.gepaeckDescriptions = this.gepaeckBelegService.getGepaeckDescriptions();
    }
    this.gepaeckDescriptions.unshift(newValue);
    args.customItem = newValue;
  }

  // inspired by https://supportcenter.devexpress.com/ticket/details/t971767/tagbox-how-to-create-a-new-item-on-tabbing-and-clicking-out-in-v20-2
  public onGepaeckStueckTagKeyDown(e: any) {
    if (e && e.component) {
      if (e.event.key === 'Tab') {
        e.event.key = 'Enter';
        e.event.keyCode = 13;
        e.event.which = 13;
      }
    }
  }

  public paymentTypeChanged(e: any): any {
    this.isProcessing = false;
    if (e?.value === undefined || e.value === null) {
      if (!environment.production) console.log('paymentTypeChanged', 'undefined or NULL');
      return;
    }
    if (!environment.production) console.log('paymentTypeChanged', e.value);
    if (!this.paymentType || this.paymentType != e.value) {
      this.gepaeckBeleg.gpblg_zktoart_id = e.value;
    }

    this.paymentType = e.value;
    this.isPaymentTypeSelected = true;

    // Ist der Auftrag bereits gespeichert oder noch nicht? ...
    if (this.gepaeckBeleg.gpblg_id === undefined || !this.gepaeckBeleg.gpblg_id || this.gepaeckBeleg.gpblg_id <= 0) {
      this.isSubmitOrderDataAndPayPalDisabled = this.paymentType !== paymentTypesEnum.payPal;
      this.buttonSubmitOrderDataAndPayPal.disabled = this.paymentType !== paymentTypesEnum.payPal;
      this.isSubmitOrderDataAndPayOneDisabled = this.paymentType !== paymentTypesEnum.payOne;
      this.buttonSubmitOrderDataAndPayOne.disabled = this.paymentType !== paymentTypesEnum.payOne;
      this.isSubmitOrderDataAndCashInAdvanceDisabled = !(this.isPaymentCashInAdvancePermitted && (this.paymentType == paymentTypesEnum.cash || this.paymentType == paymentTypesEnum.bankTransfer));
      this.buttonSubmitOrderDataAndCashInAdvance.disabled = !(this.isPaymentCashInAdvancePermitted && (this.paymentType == paymentTypesEnum.cash || this.paymentType == paymentTypesEnum.bankTransfer));
    } else {
      this.isSubmitOrderDataAndPayPalDisabled = true;
      this.buttonSubmitOrderDataAndPayPal.disabled = true;
      this.isPayPalButtonsDisabled = this.paymentType !== paymentTypesEnum.payPal;
      this.isSubmitOrderDataAndPayOneDisabled = true;
      this.buttonSubmitOrderDataAndPayOne.disabled = true;
      this.isSubmitOrderDataAndCashInAdvanceDisabled = !(this.paymentType == paymentTypesEnum.cash || this.paymentType == paymentTypesEnum.bankTransfer);
      this.buttonSubmitOrderDataAndCashInAdvance.disabled = !(this.paymentType == paymentTypesEnum.cash || this.paymentType == paymentTypesEnum.bankTransfer);
    }


    this.scrollPageToBottom();
    return;
  }

  public onGepaeckStueckTagInput(e: any) {
    this._gepaeckStueckValue = e.component.field().value;
  }

  public onGepaeckStueckTagFocusOut(e: any) {
    // ersetze Hochkomma durch unkritisches Zeichen ...
    if (e && e.component) {
      if (this._gepaeckStueckValue) {
        this._gepaeckStueckValue = this._gepaeckStueckValue.toString().replace('\'', '´').trim();
        e.component.option(
          'value',
          e.component.option('value')
            ? e.component.option('value').concat(this._gepaeckStueckValue)
            : [e.component.field().value]
        );
      }
    }
  }

  public getIsDisableEditingFaehre(): boolean {
    //return this.isDisableEditing || this.gepaeckBeleg.gpblg_dummy_abgang_fort_id === undefined;
    return this.isDisableEditingData;
  }

  public getIsEditMobileTanDisabled(): boolean {
    return this.isEditMobileTanDisabled;
  }

  public getIsPayPalButtonsDisabled(): boolean {
    return this.isPayPalButtonsDisabled;
  }

  public goBack(): void {
    if (this.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/').then();
    }
  }

  popoverToggleDefault() {
    this.popoverVisible = !this.popoverVisible;
  }

  getMaxCardWidth(): string {
    return this.maxCardWidth;
  }

  private doInit() {
    /* Regel fuer Gepaeckkunden:
    * bis 16:00 eines Tages fuer den Folgetag, nach 16.00 fuer den uebernaechsten Tag buchen
    * */
    this.initialLoading = true;
    if (this.inputFahrplanID === undefined) {
      this.inputFahrplanID = 0;
    }
    if (this.inputFahrplanID > 0) {
      this.gepaeckBeleg.fp_id = this.inputFahrplanID;
    }
    this.gepaeckBeleg.gpblg_extern_transpbed_bestaetigt_yn = 0;

    if (this.inputGepaeckTAN && this.inputGepaeckTAN > 0 && (this.inputGepaeckID && this.inputGepaeckID > 0)) {
      this.gepaeckBelegService.getGepaeckBeleg(this.inputGepaeckID, this.inputGepaeckTAN).subscribe((res: GepaeckBeleg) => {
        if (!environment.production) console.log('doInit() getGepaeckBeleg', res);
        this.gepaeckBeleg = res;
        this.isDisableEditingData = true;
        this.isDisableEditingFaehre = true;
        this.isMobileTanGenerated = true;
        this.isProcessing = false;
        if (this.gepaeckBeleg.gpblg_zktoart_id === null && this.paymentTypeDddw !== undefined && this.paymentTypeDddw.length === 1) {
          this.gepaeckBeleg.gpblg_zktoart_id = this.paymentTypeDddw[0].zktoart_id;
          this.paymentType = this.gepaeckBeleg.gpblg_zktoart_id;
        }
        this.getIsValidOrderData();
      });
    }

    if (!this.gepaeckBeleg.fp_id || this.gepaeckBeleg.fp_id < 0) {
      // erste grobe Vorbelegung ...
      this.gepaeckBeleg.gpblg_dummy_datum = new Date(this.gepaeckBelegService.getMinimalStartDate().setHours(0, 0, 0, 0));
      this.startDate = new Date(this.gepaeckBelegService.getMinimalStartDate().setHours(0, 0, 0, 0));
    }

    //  Frachtorte Radiobuttons laden aus Settings ...
    if (!environment.production) console.log('doInit() vor reselectStreckeSettingAfterRichtungChange()', this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
    this.reselectStreckeSettingAfterRichtungChange(this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
    if (!environment.production) console.log('doInit() nach reselectStreckeSettingAfterRichtungChange()', this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
    this.radioGroupItemsFrachtOrte = this.streckeSettingService.getStreckeRadioGroupItems(this.streckeSettings);

    if (!environment.production) console.log('doInit() vor  this.adresseService.getGepaeckAdrDddwNow()');
    this.gepaeckAdresseDddw = [];
    this.adresseService.getGepaeckAdrDddwNow().subscribe(resGepaeckAdr => {
      this.gepaeckAdresseDddw = resGepaeckAdr;
      if (!environment.production) console.log('getGepaeckAdrDddwNow()', this.gepaeckAdresseDddw.length ?? 0, this.inputFahrplanID);
    });

    if (!!this.gepaeckBeleg.fp_id) {
      this.fahrplanService.getById(this.gepaeckBeleg.fp_id)
        .then((resFahrplan) => {
          if (!environment.production) console.log('fahrplanService.getById ', this.gepaeckBeleg.gpblg_dummy_fp_art_kz, resFahrplan);
          if (!!resFahrplan && resFahrplan.length > 0) {

            // jetzt die Settings aus dem Fahrplan aktualisieren
            this.reselectStreckeSettingAfterRichtungChange(this.gepaeckBeleg.gpblg_dummy_fp_art_kz, resFahrplan[0].fp_abfahrt_fort_id, resFahrplan[0].fp_ankunft_fort_id);
            this.gepaeckBeleg.fp_id = this.inputFahrplanID;
            this.gepaeckBeleg.fp_abfahrt_fort_id = resFahrplan[0].fp_abfahrt_fort_id;
            this.gepaeckBeleg.gpblg_dummy_abgang_fort_id = resFahrplan[0].fp_abfahrt_fort_id;
            this.gepaeckBeleg.gpblg_dummy_ziel_fort_id = resFahrplan[0].fp_ankunft_fort_id;
            this.gepaeckBeleg.gpblg_dummy_datum = new Date(new Date(resFahrplan[0].fp_abfahrt_datzeit).setHours(0, 0, 0, 0));
            this.reselectStreckeSettingAfterRichtungChange(this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
            // this.startDate = this.gepaeckBeleg.gpblg_dummy_datum;
            if (new Date(resFahrplan[0].fp_abfahrt_datzeit) < this.startDate) {
            }
          }
        });
    }

    if (this.gepaeckBeleg.fp_id === undefined || this.gepaeckBeleg.fp_id < 1) {
      // ohne Fahrplan ...
      this.switchTransportArtCode(this.gepaeckBeleg.gpblg_dummy_fp_art_kz).then(() => {
        this.lookupFahrplans().then(() => {
          this.scrollPageToTop();
          return;
        });
        if (!environment.production) console.log('doInit 1065  ohne Fahrplan', this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
        this.reselectStreckeSettingAfterRichtungChange(this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);

        this.gepaeckBeleg.gpblg_dummy_datum = this.streckeSettingService.getMinimalStartDateByPattern(this.currentStreckeSetting?.svstrs_gepaeckbuchungsfristregel_code);
        this.gepaeckBeleg.gpblg_dummy_datum = new Date(this.gepaeckBeleg.gpblg_dummy_datum.setHours(0, 0, 0, 0));
        this.gepaeckBeleg.gpblg_dummy_abgang_fort_id = this.currentStreckeSetting?.svstrs_ort1_fort_id;
      });

      if ((this.authenticationService.isAuthenticated && this.authenticationService.isCustomer)
        || !this.authenticationService.isAuthenticated) {
        this.startDate = this.gepaeckBeleg.gpblg_dummy_datum;
      }
    }
    if ((this.authenticationService.isAuthenticated && this.authenticationService.isCustomer)
      || !this.authenticationService.isAuthenticated) {
      this.startDate = this.gepaeckBeleg.gpblg_dummy_datum;
    }
    this.initialLoading = false;
    if (!environment.production) console.log('fp_id ', this.gepaeckBeleg.fp_id);
    if (this.gepaeckBeleg.gpblg_dummy_abgang_fort_id && this.gepaeckBeleg.gpblg_dummy_abgang_fort_id > 0) {
      this.lookupFahrplans().then(() => {
        this.scrollPageToTop();
        return;
      });
    }
    if (!environment.production) console.log('fp_id ', this.gepaeckBeleg.fp_id);
    this.scrollPageToTop();
    return;
  }

  private scrollPageToBottom() {
    if (this.initialLoading) {
      return;
    }
    window.setTimeout(function () {
      document.querySelector('.footer').scrollIntoView({behavior: 'smooth', block: 'end'});
    }, 3);
  }

  private scrollPageToTop() {
    window.setTimeout(function () {
      document.querySelector('.sits-canvas-container').scrollIntoView({behavior: 'smooth', block: 'start'});
      document.querySelector('.sits-canvas').scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 5);
  }

  private switchTransportArtCode(typeOfTransport: string): Promise<IStreckeSetting[]> {
    return this.streckeSettingService.getSettingsByTransportType(typeOfTransport).then((strRes) => {
      this.streckeSettings = strRes;
      if (strRes != undefined && strRes.length > 0) {
        this.currentStreckeSetting = strRes[0];
        this.gepaeckBeleg.gpblg_dummy_abgang_fort_id = strRes[0].svstrs_ort1_fort_id;
        this.gepaeckBeleg.gpblg_dummy_ziel_fort_id = strRes[0].svstrs_ort2_fort_id;
        this.gepaeckBeleg.gpblg_dummy_fp_art_kz = strRes[0].svstrs_fp_art_kz;
        this.radioGroupItemsFrachtOrte = this.streckeSettingService.getStreckeRadioGroupItems(strRes);
      }
      return this.streckeSettings;
    });
  }

  private reselectStreckeSettingAfterRichtungChange(typeOfTransport: string, fromOrtId: number, toOrtId: number) {
    this.streckeSettingIndex = this.streckeSettings.findIndex(e =>
      e.svstrs_fp_art_kz == typeOfTransport
      && e.svstrs_ort1_fort_id == fromOrtId
      && e.svstrs_ort2_fort_id == toOrtId);
    if (this.streckeSettingIndex >= 0) {
      this.currentStreckeSetting = this.streckeSettings[this.streckeSettingIndex];
    }
    this.startDate = this.streckeSettingService.getMinimalStartDateByPattern(this.currentStreckeSetting?.svstrs_gepaeckbuchungsfristregel_code);
  }

  private monitorPayOne(): void {

    const filters: ColumnValueFilter[] = [new ColumnValueFilter()];
    // var paymentLogData: PaymentLog[] = [];
    // var showCancelButtonDate = new Date(new Date().setHours(0, 0, 0, 0) + (2 * 60 * 1000)); // in 2 min

    this.$paymentLookupInterval.subscribe(() => {
      if (!this.isPayOnePopupVisible) return;
      filters[0] = {column: 'pylg_payment_interface_code', value: 'PAYONE'};
      filters[1] = {column: 'pylg_shoporder_guid', value: this.gepaeckBeleg.gpblg_shoporder_guid};
      filters[2] = {column: 'pylg_direction_code', value: 'IN'};
      this.paymentPayOneService.getPaymentLogByFilter(filters).then((resLog: PaymentLog[]) => {
        if (resLog === undefined || resLog.length === undefined || resLog.length < 1) {
          this.payOneCurrentStatusText = 'keine Protokollinformationen!';
          if (!environment.production) console.log('getPaymentLogByFilter undefined Log data');
          return;
        }
        let logCount = resLog.length;
        logCount = logCount > 2 ? 2 : logCount;

        switch (true) {
          case (resLog[logCount - 1].pylg_process_name.includes('appointed')):
          case (logCount > 1 && resLog[logCount - 2].pylg_process_name.includes('appointed')):
            this.payOneCurrentStatusText = 'Zahlung ist erfolgt.';

            this.isPayOnePopupVisible = false;
            notify({
              message: 'Die Zahlung war erfolgreich. Wir haben Ihren Auftrag aktiviert. ' +
                'Siehe auch Link zum Auftrag in SMS und / oder E-Mail.',
              width: this.notifyWidth,
              position: {
                my: 'center',
                at: 'center'
              }
            }, 'success', 4000);
            this.isDisableEditingData = true;
            this.router.navigate(['/gepaeckauftrag/show', this.gepaeckBeleg.gpblg_id, this.gepaeckBeleg.gpblg_extern_mobiletan]);
            return;
          case (resLog[logCount - 1].pylg_process_name.includes('failed')):
          case (logCount > 1 && resLog[logCount - 2].pylg_process_name.includes('failed')):
            this.payOneCurrentStatusText = '';
            this.isPayOnePopupVisible = false;
            this.isSubmitOrderDataAndPayOneDisabled = false;
            this.paymentPayOnePayLinkUrl = null;
            notify({
              message: 'Die Zahlung wurde abgebrochen. Ihr Auftrag wird erst NACH erfolgreicher Zahlung aktiviert. ' +
                'Sie können die Zahlung jetzt nochmals starten.',
              width: this.notifyWidth,
              position: {
                my: 'center',
                at: 'center'
              }
            }, 'warning', 4000);
            this.isDisableEditingData = true;
            return;

          case (resLog[logCount - 1].pylg_process_name == 'PAYONE_LINK_EXECUTION REDIRECTED'):
          case (logCount > 1 && resLog[logCount - 2].pylg_process_name == 'PAYONE_LINK_EXECUTION REDIRECTED'):
            this.payOneCurrentStatusText = 'weitergeleitet an ' + resLog[logCount - 1].pylg_provider_code;
            return;
          default:
            break;
        }
      });
    });
  }
}



