<div class="sits-container">
  <div class="sits-card-center dx-card sits-card-large">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">Impressum / Info</div>
      <dx-toolbar>
        <dxi-item widget="dxButton" location="before" locateInMenu="auto">
          <dx-button icon="back" text="zurück" (click)="this.goBack()"></dx-button>
        </dxi-item>
      </dx-toolbar>
      <div class="dx-field-item-content">
        <p>Dies ist eine Seite der DB Fernverkehr AG
        <p>
        <p><strong>DB Fernverkehr AG</strong><br/>
          Europa-Allee 78-84<br/>
          60326 Frankfurt am Main</p>
        <p>Die DB Fernverkehr AG wird vertreten durch den Vorstand:<br/>
          Dr. Michael Peterson (Vorstandsvorsitzender), Anja Schöllmann (Produktion), Wilken Bormann (Finanzen), Martin Jende (Personal) und Stefanie Berk (Marketing)</p>
        <p>Registergericht Frankfurt am Main HRB 83 173<br/>
          USt-IdNr. DE260656754</p>
        <p>Aufsichtsbehörde:</p>
        <p>Eisenbahn-Bundesamt<br/>
          Heinemannstraße 6<br/>
          53175 Bonn</p>
        <p>Plattform der EU-Kommission zur Online-Streitbeilegung:<br/>
          <a href="https://www.ec.europa.eu/consumers/odr" target="_blank"
             rel="noopener">www.ec.europa.eu/consumers/odr</a>
        </p>
        <p>Schlichtungsstellen:<br/>
          Zur Übersicht der <a
            href="https://www.bahn.de/p/view/service/auskunft/fahrgastrechte/fahrgastrechte_schlichtung.shtml"
            target="_blank" rel="noopener">Schlichtungsstellen</a></p>
        <p>Nutzungshinweise:<br/>Hier erhalten Sie alle <a
          href="https://www.db-fernverkehr.com/fernverkehr/view/nutzungshinweise.shtml" target="_blank" rel="noopener">rechtlichen
          Hinweise</a> der DB Fernverkehr AG</p>
        <p><strong>Weitere Informationen und Kontaktmöglichkeiten:</strong></p>
        <p>Weitere Informationen erhalten sie unter <a href="https://www.bahn.de/" target="_blank"
                                                       rel="noopener">www.bahn.de</a>. </p>
        <p><strong>Servicenummer der DB:</strong> 030 297 0<br/>Rund um die Uhr Auskünfte über Fahrpreise und Fahrpläne,
          Informationen über die Serviceleistungen der Deutschen Bahn und zur BahnCard</p>
        <p><strong>Fundservice: </strong> 030 586020909<br/>Im Zug oder Bahnhof verlorene oder gefundene Gegenstände
          melden
        </p>
        <p><strong>Servicecenter Fahrgastrechte:</strong> 030 586020920<br/>
          Informationen zu Fahrpreiserstattungen im Rahmen
          der EU-Fahrgastrechteverordnung</p>
        <p><strong>Mobilitätsservice-Zentrale: </strong>030 65212888<br/>
          Kontakt für die Planung barrierefreier Reisen</p>
        <p><strong>Kostenloses BahnBau-Telefon: </strong>0800 5 99 66 55<br/>
          Aktuelle Informationen erhalten Sie auch unter <a href="https://deutschebahn.com/bauinfos" target="_blank"
                                                            rel="noopener">www.deutschebahn.com/bauinfos</a></p>

        <p>Die hier gegebenen Informationen erfolgen zur Erfüllung nachfolgend genannter gesetzlicher
          Verpflichtungen:<br><br>§ 6 und § 7 Teledienstgesetz (TDG)<br>§ 4 Abs. 3
          Bundesdatenschutzgesetz (BDSG)<br>§ 312c Bürgerliches Gesetzbuch (BGB)<br>§ 1
          BGB-Informationspflichten-Verordnung (BGB-InfoV)
        </p>
        <p>
          siehe auch <a href="https://www.siw-wangerooge.de/siw-de/Impressum-5977912" target="_blank">Impressum
          www.siw-wangerooge.de</a></p>
      </div>
      <p><strong>Sonstiges:</strong></p>
      <p>Bildschirmauflösung (aktuell, netto): {{screenWidth}} x {{screenHeight}}</p>
      <p>Konzeption, Design, Programmierung und Betrieb:<br>
        <b>Scheltwort IT-Services KG</b><br>
        34117 Kassel&nbsp;<a href="https://www.scheltwort-its.de" target="_blank">www.scheltwort-its.de</a></p>
      <br>
      <p>
    </div>
  </div>
</div>
