<div class="card-text sits-print-only">
  <b>Abreise:</b>&nbsp;<small><i><span class="sits-color-serious">(Gepäck-Abholung von Inseladresse -> {{frachtOrtName1}})</span></i></small><br>Stellen Sie Ihr Gepäck bitte <b>1 1/2 Std.</b>, <b>außerorts</b> bitte <b>2 Std.</b> vor Schiffsabfahrt deutlich sichtbar zur Abholung bereit.<br>
  <b>Anreise:</b>&nbsp;<small><i><span class="sits-color-serious">(Gepäck-Zustellung {{frachtOrtName1}} -> Inseladresse)</span></i></small><br>Bitte jedes Gepäckstück mit einer Banderole kennzeichnen sowie einem Anhänger mit Mobiltelefonnummer bei Rückfragen.<br>
  Geben Sie das Gepäck bitte auf der Insel am Gepäckschalter von {{frachtOrtName1}} ab.<br>
  Vielen Dank für Ihre Unterstützung.<br>
  Um alles weitere kümmern wir uns.<br><br>
</div>

<dx-popover
  target="#link1"
  position="bottom"
  cdkScrollable
  [width]="350"
  [showTitle]="true"
  [title]="titleText"
  [(visible)]="inputPopoverVisible">
  <div *dxTemplate="let data = model of 'content'">
    <small><b>Hier geht es nur um <u>Gepäcktransport</u>, NICHT um Fahrkarten</b></small><br>
    <b>Abreise:</b>&nbsp;<small><i><span class="sits-color-serious">(Gepäck-Abholung Inseladresse -> {{frachtOrtName1}})</span></i></small><br>Stellen Sie das Gepäck bitte <b>1 1/2 Std.</b>, außerorts bitte <b>2 Std.</b> vor Schiffsabfahrt deutlich sichtbar zur Abholung bereit.<br>
    <b>Anreise:</b>&nbsp;<small><i><span class="sits-color-serious">(Gepäck-Zustellung {{frachtOrtName1}} -> Inseladresse)</span></i></small><br>Bitte jedes Gepäckstück mit einer Banderole kennzeichnen sowie einem Anhänger mit Mobiltelefonnummer bei Rückfragen.<br>
    Geben Sie das Gepäck bitte auf der Insel am Gepäckschalter von {{frachtOrtName1}} ab.<br>
    An- und Abreise bitte <b>separat buchen</b>.
  </div>
</dx-popover>
