<div class="sits-container">
  <div class="sits-card-center dx-card sits-card-large">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">
        <dx-toolbar>
          <dxi-item widget="dxButton" location="before">
            <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
          </dxi-item>
          <dxi-item location="left" widget="dxButton" locateInMenu="never">
            <div *dxTemplate>
              <div class="toolbar-label">{{[this.componentTitle]}}</div>
            </div>
          </dxi-item>
        </dx-toolbar>
      </div>

      <div class="dx-field-item-content">
        <p>Mit diesem Hinweis informieren wir Sie darüber, welche Daten wir von Ihnen erheben, wie wir sie
          nutzen und
          wie Sie der Datennutzung widersprechen können.</p>

        <p><strong>Datenschutzgrundsätze</strong></p>

        <p>Eine Nutzung unserer Internetseite ist grundsätzlich ohne Angabe personenbezogener Daten möglich.
          Sofern Sie
          besondere Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchten, kann
          jedoch eine
          Verarbeitung personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener
          Daten
          erforderlich und besteht für eine solche Verarbeitung keine gesetzliche Grundlage (z.B. die
          Durchführung einer
          vertraglichen Vereinbarung), bitten wir Sie um Ihre Einwilligung.</p>
        <p>Mit diesem Hinweis informieren wir Sie darüber, welche Daten wir von Ihnen erheben, wie wir sie
          nutzen und
          wie Sie der Datennutzung widersprechen können. Uns ist bewusst, wie wichtig Ihnen der sorgfältige
          Umgang mit
          Ihren personenbezogenen Daten bei Inanspruchnahme unserer Dienstleistungen ist.</p>
      </div>
      <br>
      <clr-accordion>
        <clr-accordion-panel>
          <clr-accordion-title>Wer ist verantwortlich für die Datenerhebung und -verarbeitung?
          </clr-accordion-title>
          <clr-accordion-content><p>Die <b>DB Fernverkehr AG</b>, Inselverkehr Wangerooge, Hafen Harlesiel, 26409
            Harlesiel erhebt und verarbeitet Ihre Daten als verantwortliche Stelle.</p>
            <p>Die bestellte Datenschutzbeauftragte ist Frau Dr. Marein Müller.</p>
            <p>Sollten Sie Fragen oder Anregungen zum Datenschutz haben, können Sie eine E-Mail richten an (<a
              src="mailto:Fv-datenschutz@deutschebahn.com">Fv-datenschutz&#64;deutschebahn.com</a>) oder kontaktieren Sie
              uns bitte über die oben genannte Adresse.</p>
            <p>Sollten Sie Fragen, Anregungen und/oder Kritik in Bezug auf siw-wangerooge.de bzw.
              https://gepaeck.siw-wangerooge.de/ haben, so kontaktieren Sie uns bitte per E-Mail: <a
                src="mailto:siw-wangerooge@deutschebahn.com">siw-wangerooge&#64;deutschebahn.com</a> oder per Post: DB
              Fernverkehr AG, Inselverkehr Wangerooge, Hafen Harlesiel 26409 Harlesiel</p>
          </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel>
          <clr-accordion-title>Welche Daten erheben wir und wie und warum verarbeiten wir Ihre Daten?
          </clr-accordion-title>
          <clr-accordion-content>
            <p>Wir erheben und verarbeiten Ihre Daten ausschließlich zu bestimmten Zwecken. Diese können
              sich aus technischen Notwendigkeiten, vertraglichen Erfordernissen oder ausdrücklichen
              Nutzerwünschen ergeben.</p>
            <p>Aus technischen Gründen müssen beim Besuch von <strong>siw-wangerooge.de</strong> bzw.
              bei der Buchung über&nbsp;<strong>https://gepaeck.siw-wangerooge.de</strong>&nbsp;und die
              Abrechnung über den Zahlungsdienstleister BS Payone GmbH bestimmte Daten erhoben und
              gespeichert werden wie z.B. das Datum und die Dauer des Besuchs, die genutzten Webseiten,
              die Erkennungsdaten des verwendeten Browser- und Betriebssystemtyps sowie die Webseite, von
              der aus Sie uns besuchen.</p>
            <p>Zur Erfüllung eines Vertrages benötigen wir von Ihnen personenbezogene Daten. Diese Daten
              werden dazu benötigt, Ticketbuchungen, Zahlungsabwicklungen, Bonitätsprüfungen, Benachrichtigungen und um ggf.
              die Abwicklung von Stornierungen und Erstattungen &nbsp;durchzuführen.</p>
            <p>Dies betrifft im Einzelnen:&nbsp;<strong>Vorname, Name, Telefon, E-Mail-Adresse</strong></p>
            <p><strong>Registrieren bei</strong>&nbsp;https://gepaeck.siw-wangerooge.de</p>
            <p>Um ein Kundenkonto auf https://gepaeck.siw-wangerooge.de anzulegen, werden die folgenden
              Pflichtangaben erhoben. Ohne Angabe dieser Daten kann kein persönliches Konto erstellt
              werden.&nbsp;</p>
            <ul class="rte--list">
              <li>Vor- und Nachname</li>
              <li>Mobilfunknummer</li>
              <li>E-Mail-Adresse</li>
            </ul>
            <p>Wir speichern Ihre Buchungsdaten und nutzen sie auch zu internen Analyse- und Marktforschungszwecken.
              Wir wollen Erkenntnisse daraus allgemein dazu nutzen, um unser
              Angebot ständig zu verbessern. Diesem Ziel dient auch die Speicherung und Analyse von
              Nutzungsdaten aus dem Online-Bereich auf pseudonymer Basis. Eine Verbindung zu Ihren
              personenbezogenen Daten wird hierbei nicht hergestellt. Der pseudonymen Nutzung von Daten,
              die bei Inanspruchnahme von Online-Diensten der Bahn entstehen, können Sie jederzeit
              widersprechen.<br>
              Die Daten werden 30 Tage nach Abwicklungstermin/Leistungsdatum anonymisiert. Name, Mail-Adresse, Mobiltelefonnummer werden dann aus den Auftragsdaten entfernt oder nicht rekonstruierbar verschleiert.</p>
            <p><strong>Zahlungsdaten auf</strong>&nbsp;https://system.payplace.de (BS Payone GmbH)</p>
            <p>Für die Abwicklung von Zahlungen in Verbindung mit Gepäcktransport-Buchungen werden Zahlungsdaten
              wie Kreditkartendaten, Kontakt- und Identifizierungsdaten erhoben.</p>
            <p>Für die Autorisierung der Kreditkartenzahlung wird bei jedem Zahlungsvorgang die Prüfziffer
              abgefragt. Diese wird nicht gespeichert.</p>
            <p><strong>Buchung eines Online-Tickets</strong></p>
            <p>Für die Kontrolle von Online-Tickets werden bei der Buchung Kontakt- und
              Identifizierungsdaten erfasst. Die Kontrolle am Gate erfolgt mit dem Papierausdruck bzw. mit dem Ticket auf dem Mobiltelefon.</p>
            <p><strong>Geltendmachung von Fahrgastrechten</strong></p>
            <p>Wenn Sie bei uns Fahrgastrechte geltend machen, erheben wir für unsere interne Buchhaltung
              die Daten zum Fahrtverlauf, ggf. Ihren Namen und Ihre Adresse. Für den Fall, dass Sie eine
              Überweisung wünschen, erheben wir Ihre Kontoverbindung zum Zweck der Auszahlung an Sie.</p>
          </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel>
          <clr-accordion-title>Werden Daten weitergegeben?</clr-accordion-title>
          <clr-accordion-content>
            <div class="detail-content">
              <p>Für die Vertragsabwicklung ist in der Regel die Einschaltung weisungsabhängiger
                Auftragsverarbeiter erforderlich, wie z. B. von Rechenzentrumsbetreibern, Druck- oder
                Versanddienstleistern oder sonstigen &nbsp;an der Vertragserfüllung Beteiligten.</p>
              <p>Externe Dienstleister, die für uns im Auftrag Daten verarbeiten, werden von uns
                sorgfältig ausgewählt und vertraglich streng verpflichtet. Die Dienstleister arbeiten
                nach unserer Weisung, was durch strenge vertragliche Regelungen, durch technische und
                organisatorische Maßnahmen und durch ergänzende Kontrollen sichergestellt wird.</p>
              <p>Eine Übermittlung Ihrer Daten erfolgt im Übrigen nur, wenn Sie uns dazu eine
                ausdrückliche Einwilligung erteilt haben oder aufgrund einer gesetzlichen Regelung.</p>
              <p>Eine Übermittlung in Drittstaaten außerhalb der EU/des EWR oder an eine internationale
                Organisation findet nicht statt, es sei denn, es liegen angemessene Garantien vor. Dazu
                gehören die EU-Standardvertragsklauseln sowie ein Angemessenheitsbeschluss der
                EU-Kommission.</p>
              <p>Eine Weitergabe wird zum Zwecke der Vertragsabwicklung bei Buchung der Fährkarten auf https://gepaeck.siw-wangerooge.de ist erforderlich an: Scheltwort IT-Services KG, BS Payone GmbH (Zahlungsdienstleister) und Seven communications GmbH & Co. KG, Kiel (Authentifizierung, SMS-Benachrichtigungen)</p>
              <p>Sofern Sie Ihre Fahrgastrechte geltend machen, werden diese im Fahrgastrechtezentrum
                bearbeitet <a href="https://www.bahn.de/p/view/service/auskunft/fahrgastrechte"
                              target="_blank"
                              rel="noopener">https://www.bahn.de/p/view/service/auskunft/fahrgastrechte</a>
              </p>
              <p>Zur telefonischen Kundenbetreuung kann ein Callcenter eingeschaltet werden.&nbsp;</p>
              <p>Bei der Nutzung der Kontaktformulare auf siw-wangerooge.de für das Service Center des
                Inselverkehrs Wangerooge, DB Fernverkehr AG, werden die eingegebenen Daten nicht &nbsp;weitergegeben.
                Auf <a href="https://siw-wangerooge.de" target="_blank"
                       rel="noopener">siw-wangerooge.de</a> werden lediglich die Formulare hierfür
                bereitgestellt.</p></div>
          </clr-accordion-content>
        </clr-accordion-panel>
        <clr-accordion-panel>
          <clr-accordion-title>Wie lange werden Ihre Daten gespeichert?</clr-accordion-title>
          <clr-accordion-content>
            <p>Wir speichern Ihre Daten nur so lange, wie sie für die Erfüllung des Zwecks, zu dem sie
              erhoben wurden (bspw. im Rahmen eines Vertragsverhältnisses), erforderlich sind oder sofern
              dies gesetzlich vorgesehen ist. So speichern wir im Rahmen eines Vertragsverhältnisses Ihre
              Daten mindestens bis zur vollständigen Beendigung des Vertrages. Anschließend werden die
              Daten für die Dauer der gesetzlichen Aufbewahrungsfristen aufbewahrt.&nbsp;</p>
          </clr-accordion-content>
        </clr-accordion-panel>
        <clr-accordion-panel>
          <clr-accordion-title>Welche Rechte haben Nutzerinnen und Nutzer von siw-wangerooge.de/
            https://gepaeck.siw-wangerooge.de?
          </clr-accordion-title>
          <clr-accordion-content>
            <div class="detail-content"><p><br></p>
              <ul class="rte--list">
                <li>Sie können Auskunft darüber verlangen, welche Daten über Sie gespeichert sind.</li>
                <li>Sie können Berichtigung, Löschung und Einschränkung der Bearbeitung (Sperrung) ihrer
                  personenbezogenen Daten verlangen, solange dies gesetzlich zulässig und im Rahmen
                  eines bestehenden Vertragsverhältnisses möglich ist.
                </li>
                <li>Sie haben das Recht, Beschwerde bei einer Aufsichtsbehörde einzulegen. Die für die
                  Deutsche Bahn AG zuständige Aufsichtsbehörde ist: Berliner Beauftragte für
                  Datenschutz und Informationsfreiheit, Friedrichstr.219, 10969 Berlin, E-Mail:
                  mailbox&#64;datenschutz-berlin.de
                </li>
                <li>Sie haben das Recht auf Übertragbarkeit derjenigen Daten, die Sie uns auf der Basis
                  einer Einwilligung oder eines Vertrages bereitgestellt haben (Datenübertragbarkeit).&nbsp;
                </li>
                <li>Wenn Sie uns eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese
                  jederzeit auf demselben Wege widerrufen, auf dem Sie sie erteilt haben. Durch den
                  Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis
                  zum Widerruf erfolgten Verarbeitung nicht berührt.
                </li>
              </ul>
              <p>Für die Ausübung Ihrer Rechte reicht ein Schreiben auf dem Postweg an&nbsp;<strong>DB
                Fernverkehr AG, Inselverkehr Wangerooge, Hafen Harlesiel, 26409 Harlesiel</strong>&nbsp;oder
                per E-Mail an<strong>&nbsp;</strong><a
                  href="mailto:siw-wangerooge@deutschebahn.com"><strong>siw-wangerooge&#64;deutschebahn.com</strong></a>
              </p></div>
          </clr-accordion-content>
        </clr-accordion-panel>
        <clr-accordion-panel>
          <clr-accordion-title>Wann werden Cookies eingesetzt?</clr-accordion-title>
          <clr-accordion-content>
            <div class="detail-content"><p>Cookies sind kleine Textdateien, in denen personenbezogene Daten
              gespeichert werden können. Die Cookies können beim Aufruf einer Seite an diese übermittelt
              werden und ermöglichen somit eine Zuordnung des Nutzers. Cookies helfen dabei, die Nutzung
              von Internetseiten für die Nutzer zu vereinfachen. &nbsp;<br>Wir unterscheiden zwischen
              Cookies, die für die technischen Funktionen der Webseite zwingend erforderlich sind und
              solchen Cookies, die für die technische Funktion der Webseite nicht zwingend erforderlich
              sind.&nbsp;</p>
              <p>Wir wollen Ihnen die Möglichkeit zu einer informierten Entscheidung für oder gegen den
                Einsatz von Cookies geben, die für die technischen Funktionen der Webseite nicht
                zwingend erforderlich sind. Bitte beachten Sie, dass Sie bei Ablehnung werblich
                genutzter Cookies weniger passgenaue und auf Ihre Interessen abgestimmte Werbung
                erhalten. Die Nutzung der Webseite bleibt aber in vollem Umfang weiterhin möglich.</p>
              <p>Wir informieren Sie hier über Art und Umfang des Einsatzes von Cookies auf unseren
                Seiten:</p>
              <p>Die Nutzung von siw-wangerooge.de ist generell ohne Cookies, die nicht technischen
                Zwecken dienen, möglich. Sie können also in Ihrem Browser die Nachverfolgbarkeit durch
                Cookies verhindern (Do-not-track, Tracking-Protection-List) &nbsp;bzw. das Speichern von
                Drittanbietercookies untersagen. Außerdem empfehlen wir eine regelmäßige Kontrolle der
                gespeicherten Cookies vorzunehmen, sofern diese nicht ausdrücklich gewünscht
                sind.&nbsp;</p>
              <p>Bitte beachten Sie: Bei Löschung aller Cookies werden auch etwaige gesetzte
                Widerspruchscookies (Opt-Out-Cookies) gelöscht, sodass Sie etwaige erklärte Widersprüche
                erneut ausüben müssten.</p>
              <p>Cookies, die zwingend für die Nutzung der Seite https://gepaeck.siw-wangerooge.de
                erforderlich sind: a3b2970a4bf49933b6b457837275aea8</p></div>
          </clr-accordion-content>
        </clr-accordion-panel>
        <clr-accordion-panel>
          <clr-accordion-title>Was passiert bei Links zu externen Seiten?</clr-accordion-title>
          <clr-accordion-content>
            <p>Wenn Sie einen Link auf eine externe Seite anklicken, bewegen Sie sich außerhalb der Seiten
              von&nbsp;<strong>siw-wangerooge.de bzw. der Buchungsseite
                https://gepaeck.siw-wangerooge.de</strong>. Die&nbsp;<strong>DB Fernverkehr AG,
                Inselverkehr Wangerooge</strong>, ist somit nicht verantwortlich für den Inhalt, die
              Dienste oder Produkte, die auf der verlinkten Webseite angeboten werden und auch nicht für
              den Datenschutz und die technische Sicherheit auf der verlinkten Webseite.</p>
          </clr-accordion-content>
        </clr-accordion-panel>
        <clr-accordion-panel>
          <clr-accordion-title>Aktualisierung des Datenschutzhinweises</clr-accordion-title>
          <clr-accordion-content>
            <p>Wir passen den Datenschutzhinweis an veränderte Funktionalitäten oder geänderte Rechtslagen
              an. Daher empfehlen wir, den Datenschutzhinweis in regelmäßigen Abständen zur Kenntnis zu
              nehmen. Sofern Ihre Einwilligung erforderlich ist oder Bestandteile des Datenschutzhinweises
              Regelungen des Vertragsverhältnisses mit Ihnen enthalten, erfolgen die Änderungen nur mit
              Ihrer Zustimmung.</p>
          </clr-accordion-content>
        </clr-accordion-panel>
      </clr-accordion>
      <p>Stand: 03.06.2024</p>
      <div class="dx-field-item-content">
        <div class="sits-card-center dx-card sits-card-small">
          <p><b>TOC 1 - DeBI Fachdienst</b></p>

          <p>Deutsche Bahn AG<br>
            An der Welle 3<br>
            60322 Frankfurt<br><br>
            <a src="mailto:DeBI@deutschebahn.com">E-Mail</a><br>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
