<p>_______________________________________<br>
  DB Fernverkehr AG<br>
  Europa-Allee 78-84<br>
  60326 Frankfurt am Main<br>
  <br>

  Tel: +49 176 8563 7971<br>
  Internet: <a href="https://www.siw-wangerooge.de" target="_blank">https://www.siw-wangerooge.de</a><br>
  E-Mail: <a href="mailto:siw-wangerooge@deutschebahn.com">siw-wangerooge&#64;deutschebahn.com</a><br>

  Die DB Fernverkehr AG wird vertreten durch den Vorstand:<br>
  Dr. Michael Peterson (Vorstandsvorsitzender), Anja Schöllmann (Produktion), Wilken Bormann (Finanzen), Martin Jende (Personal) und Stefanie Berk (Marketing)<br>
<br>
  Registergericht Frankfurt am Main HRB 83 173<br>
  USt-IdNr. DE260656754<br>
<br>
  Aufsichtsbehörde:<br>
<br>
  Eisenbahn-Bundesamt<br>
  Heinemannstraße 6<br>
  53175 Bonn<br>
<br>
  Plattform der EU-Kommission zur Online-Streitbeilegung:<br>
  <a href="https://www.ec.europa.eu/consumers/odr" target="_blank">www.ec.europa.eu/consumers/odr</a><br>
<br>
  Schlichtungsstellen:<br>
  Zur Übersicht der Schlichtungsstellen<br>
<br>
  Nutzungshinweise:<br>
  Hier erhalten Sie alle <a href="https://www.db-fernverkehr.com/fernverkehr/view/nutzungshinweise.shtml" target="_blank">rechtlichen Hinweise</a> der DB Fernverkehr AG<br>
<br>
<b>Weitere Informationen und Kontaktmöglichkeiten:</b><br>
  Weitere Informationen erhalten sie unter <a href="https://www.bahn.de" target="_blank">www.bahn.de</a>.<br>
  <br>
  <b>Servicenummer der DB:</b>  030 297 0
  Rund um die Uhr Auskünfte über Fahrpreise und Fahrpläne, Informationen über die Serviceleistungen der Deutschen Bahn und zur BahnCard

  <b>Fundservice:</b>  030 586020909
  Im Zug oder Bahnhof verlorene oder gefundene Gegenstände melden

  <b>Servicecenter Fahrgastrechte:</b>  030 586020920
  Informationen zu Fahrpreiserstattungen im Rahmen der EU-Fahrgastrechteverordnung

  <b>Mobilitätsservice-Zentrale:</b>  030 65212888
  Kontakt für die Planung barrierefreier Reisen

  <b>Kostenloses BahnBau-Telefon:</b> 0800 5 99 66 55
  Aktuelle Informationen erhalten Sie auch unter <a href="https://www.deutschebahn.com/bauinfos" target="_blank">www.deutschebahn.com/bauinfos</a>
</p>
