// noinspection JSUnusedGlobalSymbols
export const environment = {
  production: true,
  version: '2.3.54.535',
  restApiUrl: 'https://api1042611.einfachmacher.de/api/v2.3.4',
  sitsApiKey: 'rLT2NLPUdjRFQqvQ',
  aes256CtrVector: 'k6qRXoA1bZglk',
  statisticServiceApiUrl: '',
  logStatistics: false,
  imprintUrl: 'https://www.inselspedition.de/impressum',
  sessionKey: '',
  targetKey: '10426',
  mainlandLocationName: 'Neuharlingersiel,DE',
  islandLocationName: 'Spiekeroog,DE',
  enableServiceWorker: true,
  externalGepaeckInterface: true
};
