import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {AdresseDddw} from '../../../api/api-dtos/adresse-dddw';
import {Geraet} from '../../../api/api-dtos/geraet';
import {GeraeteVermietungStorageBox} from '../../../api/api-dtos/geraete-vermietung-storage-box';
import {Subject} from 'rxjs/internal/Subject';
import {DxFormComponent, DxValidationGroupComponent} from 'devextreme-angular';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../shared-services/alert.service';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {GeraetService} from '../../../api/api-services/geraet.service';
import {SmsService, SmsTanType} from '../../../api/api-services/sms.service';
import {StorageBoxService} from '../../../api/api-services/storage-box.service';
import {Location} from '@angular/common';
import notify from 'devextreme/ui/notify';
import {ITan} from '../../../api/api-dtos/itan';
import {paymentTypesEnum} from '../../../api/api-services/paymenttypes';
import {ServerMessage} from '../../../api/api-dtos/server-message';
import {IArtikel} from '../../../api/api-dtos/artikel';
import {LockService} from '../../../api/api-services/lock.service';
import {Lock} from '../../../api/api-dtos/lock';
import {AccountingService} from '../../../api/api-services/accounting.service';
import {ZahlKontoArt} from '../../../api/api-dtos/zahl-konto-art-dto';
import {DxiItemComponent} from 'devextreme-angular/ui/nested';
import {environment} from '../../../../environments/environment';
import {ArtikelService} from '../../../api/api-services/artikel.service';

declare let paypal: any;

@Component({
  selector: 'app-storagebox-order-guest-edit',
  templateUrl: './storagebox-order-guest-edit.component.html',
  styleUrls: ['./storagebox-order-guest-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StorageboxOrderGuestEditComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  get isValidStorageBoxId(): boolean {
    return this._isValidStorageBoxId;
  }

  set isValidStorageBoxId(value: boolean) {
    this._isValidStorageBoxId = value;
  }

  get isDisableEditingLagerBox(): boolean {
    return this._isDisableEditingLagerBox;
  }

  set isDisableEditingLagerBox(value: boolean) {
    this._isDisableEditingLagerBox = value;
    this.gervmGerNrEditorOptions.disabled = value;
    setTimeout(() => {
      this.storageboxForm.instance.getEditor('gervm_ger_nr').option('disabled', value);
    }, 1000);

  }

  get isDisableEditingData(): boolean {
    return this._isDisableEditingData;
  }

  set isDisableEditingData(value: boolean) {
    this._isDisableEditingData = value;
  }

  public maxCardWidth = 'auto';
  public submitButtonText = new BehaviorSubject<string>('Lagerbox buchen');
  popoverVisible = false;
  submitOrderDisabled = new BehaviorSubject<boolean>(true);
  validatePhoneNumber = new BehaviorSubject<boolean | undefined>(false);
  isValidOrderData = false;
  isValidPhoneNumber = false;
  public notValidEndeDatzeitMessage: string = 'Ungültiges Enddatum';
  public isValidEndeDatzeit = true;
  startDate: Date = new Date();
  adresseDddw: AdresseDddw[] = [{}];
  storageBoxDddw: Geraet[] = [{}];
  defaultLagerboxServiceArtikel: IArtikel;
  ladestelleAdresseDddw: AdresseDddw[] = [{}];
  colCountByScreen: Object;
  storageBoxOrder: GeraeteVermietungStorageBox = new GeraeteVermietungStorageBox();
  public isProcessing = false;
  public isLoading = true;
  private _isValidStorageBoxId = false;
  private _isDisableEditingData = false;
  private _isDisableEditingLagerBox = true;
  public isDisableEditingBelegEmpfaenger = false;
  public isDisableEditingFaehre = true;
  public isGetApprovalCodeDisabled = true;
  public isMobileTanDisabled = true;
  public isPrintPreviewMode = false;
  public isDataValid = false;

  private interval: any;
  storageBoxDddwRefreshMilliSec: number = 700;
  private unsubscribe = new Subject<void>();

  public isMobileTanGenerated = false;
  public isGetMobileTanButtonDisabled = true;
  public isEditMobileTanDisabled = true;
  public isPayPalButtonsDisabled = true;
  public isCheckDataAndGetApprovalCodeDisabled = false;
  public isSubmitOrderDataAndCashInAdvanceDisabled = true;
  public isSubmitOrderDataAndPayPalDisabled = true;
  public isSubmitOrderDataAndPayPalVisible = false;

  public isPaymentTypeSelected = false;
  public paymentTypeDddw: ZahlKontoArt[] = [{}];
  public paymentType: paymentTypesEnum | undefined = undefined;
  public paymentTypeByBankTransfer = paymentTypesEnum.bankTransfer;
  public paymentTypeByCash = paymentTypesEnum.cash;
  public paymentTypeByPayPal = paymentTypesEnum.payPal;
  notifyWidth: number = 400;
  paidByPayPal = false;

  gervmGerNrEditorOptions = {
    dataSource: this.storageBoxDddw,
    searchEnabled: true,
    valueExpr: 'ger_nr',
    displayExpr: 'ger_name',
    placeholder: 'verfügbare Box auswählen ...',
    noDataText: 'keine verfügbare Box in diesem Zeitraum',
    deferRendering: true,
    focusIn: this.storageBoxDddwFocusIn,
    disabled: this.isDisableEditingLagerBox || this.isDisableEditingData
  };

  buttonBack = {
    text: 'zurück',
    icon: 'back',
    type: 'normal',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    onClick: () => {
      this.goBack();
      return;
    },
  };
  buttonPrint = {
    text: 'Druck-Ansicht',
    icon: 'print',
    type: 'normal',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    onClick: () => {
      this.printPreview();
      return;
    },
  };

  title = 'Lagerbox-Auftrag';

  buttonCheckDataAndGetApprovalCode: any = {
    text: '1. Daten prüfen & mobile TAN anfordern',
    type: 'default',
    disabled: this.isCheckDataAndGetApprovalCodeDisabled,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    hint: 'Sendet nach erfolgreicher Prüfung der Daten eine mobile Transaktionsnummer (TAN) an Ihr mobiles Endgerät, mit der Sie diese Buchung im nächsten Schritt bestätigen und freigeben können.',
    onClick: () => {
      this.isPayPalButtonsDisabled = true;
      this.checkDataAndGetTan().then(() => {
        return;
      });
    },
  };

  buttonSubmitOrderDataAndCashInAdvance: any = {
    text: 'Lagerbox-Auftrag buchen, separat zahlen',
    name: 'saveButton',
    type: 'default',
    disabled: this.isSubmitOrderDataAndCashInAdvanceDisabled,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    hint: 'Prüft den Lagerbox-Auftrag und sendet ihn an das Buchungssystem.',
    onClick: () => {
      this.isPayPalButtonsDisabled = true;
      this.paymentType = paymentTypesEnum.bankTransfer;
      this.onSubmitOrderData();
    },
  };
  buttonSubmitOrderDataAndPayPal: any = {
    text: 'Lagerbox-Auftrag buchen und zur PayPal-Zahlung',
    type: 'default',
    disabled: this.isPayPalButtonsDisabled,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    hint: 'Sendet Ihren Lagerbox-Auftrag an das Buchungssystem und verbindet zu PayPal.',
    onClick: () => {
      /*this.paymentType = paymentTypesEnum.payPal;*/
      this.isPayPalButtonsDisabled = true;
      this.onSubmitOrderData();
    },
  };

  // Payment, PayPal
  @ViewChild(DxFormComponent, {static: false}) storageboxForm: DxFormComponent;
  @ViewChild(DxValidationGroupComponent, {static: false}) validationStorageBoxGroup: DxValidationGroupComponent;
  @ViewChild(DxiItemComponent, {static: false})  buttonCheckData: DxiItemComponent;
  @ViewChild(DxiItemComponent, {static: false})  buttonGoToPayPal: DxiItemComponent;

  @Input() inputGepaeckAdresseDddw: AdresseDddw[];
  @Input() inputAdresseDddw: AdresseDddw[];
  inputStorageBoxOrderID: number | undefined;
  inputStorageBoxOrderTAN: number | undefined;
  public inputStorageBoxOrderGerNr: number = 0;
  public inputStorageBoxOrderBeginnDatzeit: Date | undefined;
  public inputStorageBoxOrderEndeDatzeit: Date | undefined;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private adresseService: AdresseService,
    private artikelService: ArtikelService,
    private geraetService: GeraetService,
    private smsService: SmsService,
    private storageBoxService: StorageBoxService,
    private lockService: LockService,
    private readonly location: Location,
    private accountingService: AccountingService) {
    this.getSaveButtonLabelText = this.getSaveButtonLabelText.bind(this);
    this.getDisableEditingLagerBox = this.getDisableEditingLagerBox.bind(this);
    this.getIsValidOrderData = this.getIsValidOrderData.bind(this);
    this.getIsValidPhoneNumber = this.getIsValidPhoneNumber.bind(this);
    this.getIsValidEndeDatzeit = this.getIsValidEndeDatzeit.bind(this);
    this.getIsValidGervmGerNr = this.getIsValidGervmGerNr.bind(this);
    this.getTan = this.getTan.bind(this);
    this.getMaxCardWidth = this.getMaxCardWidth.bind(this);
    this.paymentTypeChanged = this.paymentTypeChanged.bind(this);
    this.printPreview = this.printPreview.bind(this);
    this.reloadStorageBoxDddw = this.reloadStorageBoxDddw.bind(this);
    this.storageBoxDddwFocusIn = this.storageBoxDddwFocusIn.bind(this);
    this.isLoading = true;

    route.params.subscribe({
      next: params => {
        if (!!params['gervm_ger_nr'] && !isNaN(params['gervm_ger_nr'])) {
          // sehr wichtig ... Number( ... )
          this.inputStorageBoxOrderGerNr = Number(params['gervm_ger_nr']);
        }
        this.inputStorageBoxOrderBeginnDatzeit = new Date(params['gervm_beginn_datzeit']);
        this.inputStorageBoxOrderEndeDatzeit = new Date(params['gervm_ende_datzeit']);
        if (!environment.production) console.log('constructor dates ', params['gervm_beginn_datzeit'], this.inputStorageBoxOrderBeginnDatzeit, params['gervm_ende_datzeit'], this.inputStorageBoxOrderEndeDatzeit);
        if (!!params['id'] && !isNaN(params['id'])) {
          this.inputStorageBoxOrderID = Number(params['id']);
        }
        if (this.inputStorageBoxOrderID === undefined || this.inputStorageBoxOrderID < 1) {
          this.inputStorageBoxOrderID = 0;
        }
      }
    });
    this.accountingService.getZahlArtDddw().then(res => {
      this.paymentTypeDddw = res;
      this.paymentTypeDddw = this.accountingService.removeZahlArtFromDddw(this.paymentTypeDddw, 'MDSI');
      switch (environment.targetKey) {
        case '10426':
          this.paymentTypeDddw = this.accountingService.removeZahlArtFromDddw(this.paymentTypeDddw, 'PAYONE');
          break;
        case '10477':
          this.paymentTypeDddw = this.accountingService.removeZahlArtFromDddw(this.paymentTypeDddw, 'KASSE');
          this.paymentTypeDddw = this.accountingService.removeZahlArtFromDddw(this.paymentTypeDddw, 'BANK');
          this.paymentTypeDddw = this.accountingService.removeZahlArtFromDddw(this.paymentTypeDddw, 'BANK-ON');
          this.paymentTypeDddw = this.accountingService.removeZahlArtFromDddw(this.paymentTypeDddw, 'PAYPAL');
          break;
        default:
      }
    });

    if (this.inputStorageBoxOrderID == undefined || !this.inputStorageBoxOrderID || this.inputStorageBoxOrderID < 1) {
      this.inputStorageBoxOrderID = 0;
      this.storageBoxOrder = this.storageBoxService.getNewPreAllocatedStorageBoxOrder();
    }

    this.defaultLagerboxServiceArtikel = new class implements IArtikel {
    };
    this.storageBoxService.getDefaultLagerboxServiceArtikel().then(resArt => {
      if (resArt.art_nr) {
        this.defaultLagerboxServiceArtikel = resArt;
        this.storageBoxOrder.gervm_art_nr = resArt.art_nr;
        if (this.storageBoxOrder?.gervm_brutto_amount === undefined) this.storageBoxOrder.gervm_brutto_amount = null;
        // bis 2040811: this.storageBoxOrder.gervm_brutto_amount = resArt.art_brutto_preis1;
      }
    });

    this.storageBoxDddw = [{}];
    this.geraetService.getStorageStorageBoxDddw(true).then(resStbx => {
      this.storageBoxDddw = resStbx;
    });

    if (!this.authenticationService.isAuthenticated || !this.authenticationService.isCustomer) {
      this.adresseService.getDiversePortalKundenAdrId().then(resAdrId => {
        this.storageBoxOrder.gervm_rgempf_adr_id = resAdrId;
      });
    }

    /* Regel fuer Lagerbox-Kunden:
     * bis 16:00 eines Tages fuer den Folgetag, nach 16.00 fuer den uebernaechsten Tag buchen
     * */
    this.storageBoxOrder.gervm_extern_transpbed_bestaetigt_yn = this.authenticationService.isAuthenticated && this.authenticationService.isEmployee ? 1 : 0;

    if (this.inputStorageBoxOrderID > 0) {
      this.isLoading = true;
      // console.log('constructor isLoading', this.isLoading, this.inputStorageBoxOrderID);
      this.storageBoxService.getStorageBoxOrder(this.inputStorageBoxOrderID, null)
        .toPromise()
        .then((resStorageBoxOrder: GeraeteVermietungStorageBox) => {
          if (resStorageBoxOrder === undefined || resStorageBoxOrder === null) {
            notify({
              message: 'Der Auftrag konnte nicht erfolgreich geladen werden. Abbruch',
              width: this.notifyWidth,
              position: {
                my: 'center',
                at: 'center'
              }
            }, 'warning', 2000);
            this.isLoading = false;
            this.goBack();
            return;
          }
          this.submitButtonText.next('Lagerbox-Auftrag ändern');
          // this.buttonSubmitOrderDataAndCashInAdvance.text = 'Lagerbox-Auftrag speichern';
          // console.log('ngOnInit SET buttonSubmitOrderDataAndCashInAdvance.text', this.buttonSubmitOrderDataAndCashInAdvance.text);
          this.storageBoxOrder = resStorageBoxOrder;
          this.isDisableEditingData = new Date(this.storageBoxOrder.gervm_beginn_datzeit) < this.storageBoxService.getMinimalStartDate();
          this.isDisableEditingBelegEmpfaenger = (!this.authenticationService.isEmployee || this.isDisableEditingData);
          this.startDate = this.isDisableEditingData ? null : this.storageBoxService.getMinimalStartDate();

          this.reloadStorageBoxDddw();
        }, () => {
          notify({
            message: 'Der Auftrag konnte nicht geladen werden. Abbruch',
            width: this.notifyWidth,
            position: {
              my: 'center',
              at: 'center'
            }
          }, 'warning', 2000);
          this.goBack();
          return;
        });
    }

    this.adresseDddw = [];
    this.adresseService.getAllDddw().then(resAdr => {
      this.adresseDddw = resAdr;
      this.adresseService.getStorageboxAdrDddw().then(resGepaeckAdr => {
        this.ladestelleAdresseDddw = resGepaeckAdr;
      });

      this.startDate = this.storageBoxService.getMinimalStartDate();

      // console.log('constructor isAuthenticated, isCustomer gervm_rgempf_adr_id', this.authenticationService.isAuthenticated, this.authenticationService.isCustomer, this.storageBoxOrder.gervm_rgempf_adr_id, this.inputStorageBoxOrderGerNr);

      if (this.authenticationService.isAuthenticated && this.authenticationService.isCustomer) {
        this.storageBoxOrder.gervm_ladestelle_adr_id = this.authenticationService.customerId;
        this.storageBoxOrder.gervm_rgempf_adr_id = this.authenticationService.customerId;
      } else {
        if (this.inputStorageBoxOrderID == 0) {
          if (this.inputStorageBoxOrderBeginnDatzeit !== undefined && this.inputStorageBoxOrderBeginnDatzeit !== null &&
            this.inputStorageBoxOrderBeginnDatzeit >= this.storageBoxService.getMinimalStartDate()) {
            this.storageBoxOrder.gervm_beginn_datzeit = this.inputStorageBoxOrderBeginnDatzeit;
          } else {
            this.storageBoxOrder.gervm_beginn_datzeit = this.storageBoxService.getMinimalStartDate();
          }

          if (this.authenticationService.isAuthenticated && this.authenticationService.isEmployee) {
            // morgen
            this.storageBoxOrder.gervm_beginn_datzeit = new Date(new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000));
          }
          this.storageBoxOrder.gervm_beginn_datzeit.setHours(0, 0, 0, 0);
          this.storageBoxOrder.gervm_ende_datzeit = this.storageBoxService.calcRentalEndDate(this.storageBoxOrder.gervm_beginn_datzeit);

          let storageBoxLockData: Lock = this.storageBoxService.getStorageBoxLockData(0);
          storageBoxLockData.lock_tag = '*';
          this.lockService.deleteReservation(storageBoxLockData).then(() => {
            if (this.inputStorageBoxOrderGerNr !== undefined && this.inputStorageBoxOrderGerNr.valueOf() > 0) {
              this.storageBoxOrder.gervm_ger_nr = this.inputStorageBoxOrderGerNr;
              let storageBoxLockData: Lock = this.storageBoxService.getStorageBoxLockData(this.storageBoxOrder.gervm_ger_nr);
              this.lockService.createReservation(storageBoxLockData).then();
            }
          });
        }
        // console.log('constructor this.storageBoxOrder after setting ger_nr', this.storageBoxOrder, this.inputStorageBoxOrderGerNr);

        if (this.authenticationService.isAuthenticated) {
          this.adresseService.getDiversePortalKundenAdrId().then(res => {
            this.storageBoxOrder.gervm_rgempf_adr_id = res;
            this.storageBoxOrder.gervm_ladestelle_adr_id = res;
          });
        } else {
          this.adresseService.getDiversePortalKundenAdrId().then(res => {
            this.storageBoxOrder.gervm_rgempf_adr_id = res;
          });
        }
      }

      if (this.authenticationService.isAuthenticated && this.authenticationService.isEmployee) {
        this.startDate = null;
      }
      if (this.storageBoxOrder.gervm_art_nr === undefined || !this.storageBoxOrder.gervm_art_nr) {
        this.storageBoxOrder.gervm_art_nr = this.storageBoxOrder.gervm_art_nr ? this.defaultLagerboxServiceArtikel.art_nr : null;
      }
      if (this.storageBoxOrder.gervm_anz === undefined || !this.storageBoxOrder.gervm_anz) {
        this.storageBoxOrder.gervm_anz = 1;
      }

      if (this.storageBoxOrder.gervm_brutto_amount === undefined || this.storageBoxOrder.gervm_brutto_amount === null || this.storageBoxOrder.gervm_brutto_amount === 0) {
        if (this.storageBoxOrder.gervm_beginn_datzeit !== undefined && this.storageBoxOrder.gervm_beginn_datzeit !== null && this.storageBoxOrder.gervm_art_nr !== undefined ) {
          // 150 oder 160 ...;
          this.artikelService.getArtikelByDate(this.storageBoxOrder.gervm_art_nr, this.storageBoxOrder.gervm_beginn_datzeit).then( res => {
            if (res === undefined) {
              this.storageBoxOrder.gervm_brutto_amount = null;
            } else {
              this.storageBoxOrder.gervm_brutto_amount = res?.art_brutto_preis1 ?? null;
            }
          });
        } else {
          this.storageBoxOrder.gervm_brutto_amount = null;
        }
      }
      this.getDisableEditingLagerBox();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.storageboxForm?.instance?.getEditor('gervm_ger_nr').option('dataSource', this.storageBoxDddw);
      this.storageboxForm?.instance?.validate();
    }, 1000);
  }

  ngOnInit(): void {
  // console.log('ngOnInit START inputStorageBoxOrderID', this.inputStorageBoxOrderID);
    this.isDisableEditingBelegEmpfaenger = this.isDisableEditingData || !this.authenticationService.isEmployee;

    if (this.maxCardWidth === 'auto') {
      this.doResize();
    }
    window.onresize = () => {
      this.doResize();
    };
    this.reloadStorageBoxDddw();
    // this.isLoading = false;

    if (environment.targetKey == '10426') {
      paypal
        .Buttons({
        style: {
          shape: "rect",
            layout: "vertical",
            color: "silver",
            label: "paypal"
        },
          createOrder: (data: any, actions: any) => {
            return actions.order.create({
              intent: "CAPTURE",
              purchase_units: [
                {
                  reference_id: "GEP-" + this.storageBoxOrder.gervm_id.toString(),
                  description: 'Lagerbox-Service',
                  custom_id: this.storageBoxOrder.gervm_rgempf_adr_id?.toString() ?? "GAST",
                  amount: {
                    currency_code: 'EUR',
                    value: this.storageBoxOrder.gervm_brutto_amount.toFixed(2).toString(),
                    breakdown: {
                      item_total: {
                        currency_code: 'EUR',
                        value: this.storageBoxOrder.gervm_brutto_amount.toFixed(2).toString()
                      }
                    }
                  },
                  items: [
                    {
                      name: 'Lagerbox-Service-Auftrag VM-' + this.storageBoxOrder.gervm_id.toString(),
                      description: 'Bereitstellung und Einlagerung einer Transportbox zur Miete. Details: siehe SMS und. ggf. E-Mail.',
                      category: 'DIGITAL_GOODS',
                      quantity: "1",
                      unit_amount: {
                        currency_code: 'EUR',
                        value: this.storageBoxService.getPreisProStorageBox()
                      }
                    }]
                }
              ]
            });
          },
          onApprove: async (data: any, actions: any) => {
            const order = await actions.order.capture();
            if (!environment.production) console.log('After PayPal', order);
            if (order.status === 'COMPLETED') {
              // todo: zumindest order.id oder besser das ganze Object merken
              this.paidByPayPal = true;
              // console.log('After PayPal', order, this.storageBoxOrder);
              // todo: Das sollte besser der Server machen
              this.storageBoxService.signAsPayedByPayPal(this.storageBoxOrder.gervm_id,
                this.storageBoxOrder.gervm_extern_mobiletan,
                order.id,
                order.update_time,
                order)
                .then(res => {
                  notify({
                    message: 'Die Zahlung war erfolgreich. Wir aktivieren nun Ihren Auftrag. ' +
                      'Siehe auch Link zum Auftrag in SMS und ggf. E-Mail.',
                    width: this.notifyWidth,
                    position: {
                      my: 'center',
                      at: 'center'
                    }
                  }, 'success', 4000);
                  this.isDisableEditingData = true;
                  this.router.navigate(['/storagebox/order/show',
                    this.storageBoxOrder.gervm_id,
                    this.storageBoxOrder.gervm_extern_mobiletan?.valueOf()]);
                });
              // NACH PayPal-Zahlung: jetzt Mail und SMS versenden
              this.storageBoxService.notifyOrderToCustomer(this.storageBoxOrder.gervm_id,
                this.storageBoxOrder.gervm_extern_mobiletan);
            } else {
              notify({
                message: 'Die Zahlung war NICHT erfolgreich. Bitte versuchen Sie es nochmals.',
                width: this.notifyWidth,
                position: {
                  my: 'center',
                  at: 'center'
                }
              }, 'warning', 6000);
              this.isDisableEditingData = true;
              this.paidByPayPal = false;
            }
          },
          onCancel: async (data: any) => {
            notify({
              message: 'Die Zahlung wurde abgebrochen. Bitte versuchen Sie es nochmals. ',
              width: this.notifyWidth,
              position: {
                my: 'center',
                at: 'center'
              }
            }, 'warning', 6000);
            this.isDisableEditingData = true;
            this.paidByPayPal = false;
          },
          onError: (err: any) => {
            console.log(err);
            let message: string = 'Fehler beim Aufruf von PayPal.';
            const jsonMatch = err?.toString().match(/\{.*\}/);

            if (jsonMatch) {
              try {
                // Parsing des JSON-Strings in ein Objekt
                const errorJson = JSON.parse(jsonMatch[0]);
                console.log(errorJson);

                switch (errorJson?.name?.toString()) {
                  case 'UNPROCESSABLE_ENTITY':
                    message += 'Ursache: Daten können nicht verarbeitet werden. ';
                    break;
                  default:
                    message += 'Ursache:' + errorJson?.name?.toString() + ' ';
                }

                switch (errorJson?.details[0]?.issue?.toString()) {
                  case 'PAYEE_ACCOUNT_RESTRICTED':
                    message += 'Hinweis: Das Empfängerkonto ist beschränkt. ';
                    break;
                  default:
                    message += 'Hinweis:' + errorJson?.details[0]?.issue?.toString();
                }
                message += !!err?.details[0]?.description?.toString() ? '' + err?.details[0]?.description?.toString() : '';

              } catch (error) {
                console.error('PayPal onError event, Fehler beim Parsen des JSON: ', err);
              }
            }
            notify({
              message: message,
              width: this.notifyWidth,
              position: {
                my: 'center',
                at: 'center'
              }
            }, 'warning', 8000);
          }
        }).render('#paypalButtons');
    }
  }

  doResize(): void {
    if (window.innerWidth < 300) {
      this.maxCardWidth = '300px';
    } else if (window.innerWidth <= 930) {
      this.maxCardWidth = (window.innerWidth - 40).toString() + 'px';
    } else {
      this.maxCardWidth = '930px';
    }
  }

  ngOnChanges(changes: any): void {
    // console.log('ngOnChanges START inputStorageBoxOrderID', this.inputStorageBoxOrderID, this.isLoading);
    this.isLoading = true;
    this.storageBoxService.getStorageBoxOrder(this.inputStorageBoxOrderID, null)
      .toPromise()
      .then((resStorageBoxOrder: GeraeteVermietungStorageBox) => {
        if (resStorageBoxOrder === undefined) {
          this.isLoading = false;
          return;
        }
        this.storageBoxOrder = resStorageBoxOrder;
        this.isLoading = false;
        return;
      });
  }

  ngOnDestroy(): void {
    if (!environment.production) console.log('ngOnDestroy ');
    clearInterval(this.interval);

    // Emit something to stop all Observables
    this.unsubscribe.next();
    // Complete the notifying Observable to remove it
    this.unsubscribe.complete();
  }

  // Versorge Dropdown mit Daten ...

  getAdresses(options: any) {
    return {
      store: this.adresseDddw,
      filter: options.data ? ['adr_id', '=', options.data.adr_id] : null
    };
  }

  private scrollPageToBottom() {
    /*document.querySelector('#pagecard').scrollIntoView({ behavior: 'smooth', block: 'end' });*/
    document.querySelector('#pagecard').scrollIntoView(false);
  }

  async checkDataAndGetTan() {
    // let mobileTan: string;
    this.isGetMobileTanButtonDisabled = true;

    if (this.isDisableEditingData) {
      notify({
        message: 'Für einen bereits gebuchten Auftrag kann keine weitere TAN angefordert werden.',
        width: this.notifyWidth,
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'warning', 600);
      return;
    }

    this.isProcessing = true;
    if (this.getIsValidOrderData()) {
      this.isDataValid = true;
      this.scrollPageToBottom();
      notify({
        message: 'Eingaben erfolgreich geprüft. TAN wird an Ihr Mobiltelefon gesendet.',
        width: this.notifyWidth,
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'success', 1000);

      this.storageBoxOrder.gervm_extern_mobil_tel = this.storageBoxOrder.gervm_extern_mobil_tel.trim();
      await this.smsService.sendGeneratedMobileTanToUserPhoneWithPurpose(this.storageBoxOrder.gervm_extern_mobil_tel, SmsTanType.StorageBox)
        .then((resSmsResponse: ServerMessage) => {
          if (resSmsResponse?.status === undefined || resSmsResponse?.status?.valueOf() !== 'success') {
            this.isMobileTanGenerated = false;
            this.isGetMobileTanButtonDisabled = false;
            this.storageBoxOrder.gervm_extern_mobiletan = null;
          } else {
            this.isMobileTanGenerated = true;
            this.isGetMobileTanButtonDisabled = true;
            this.submitOrderDisabled.next(false);
          }
          this.isProcessing = false;
        });
    }
    this.scrollPageToBottom();
    this.isProcessing = false;
    return;
  }

  form_fieldDataChanged(e: any) {
    if (this.isLoading || this.isDisableEditingData) {
      this.isLoading = false;
      return;
    }
    if (e.component.option('formData') === undefined || e.component.option('formData') === null) {
      return;
    }
    this.storageBoxOrder = e.component.option('formData');
    // console.log('form_fieldDataChanged ', this.storageBoxOrder, e.dataField, e.value);

    switch (e.dataField) {
      case 'gervm_ger_nr':
        // alte Reservierungen canceln und dann diese Lagerbox reservieren ...
        let storageBoxLockData: Lock = this.storageBoxService.getStorageBoxLockData(this.storageBoxOrder.gervm_ger_nr);
        storageBoxLockData.lock_tag = '*';
        this.lockService.deleteReservation(storageBoxLockData).then(() => {
          if (e.value !== undefined && !!e.value) {
            storageBoxLockData = this.storageBoxService.getStorageBoxLockData(e.value);
            return this.lockService.createReservation(storageBoxLockData);
          } else {
            return;
          }
        }).then(() => {
          this.reloadStorageBoxDddw();
        });
        break;
      case 'gervm_brutto_amount':
        break;
      case 'gervm_beginn_datzeit':
        if (this.getDisableEditingLagerBox()) {
          break;
        }
        if (e.value === null) {
          this.storageBoxOrder.gervm_ende_datzeit = null;
          this.storageBoxOrder.gervm_brutto_amount = null;
          break;
        }
        let tempBeginnDatzeit: Date = new Date(e.value);
        this.storageBoxOrder.gervm_ende_datzeit = this.storageBoxService.calcRentalEndDate(e.value);
        // 150 oder 160 ...;
        this.artikelService.getArtikelByDate(this.storageBoxOrder.gervm_art_nr, tempBeginnDatzeit).then( res => {
          this.storageBoxOrder.gervm_brutto_amount = res.art_brutto_preis1 ?? null;
        });
        this.reloadStorageBoxDddw();
        this.getDisableEditingLagerBox();
        break;

      case 'gervm_ende_datzeit':
        if (e.value === null) {
          this.getDisableEditingLagerBox();
          break;
        }
        this.reloadStorageBoxDddw();
        break;

      case 'gervm_extern_mobil_tel':
        // TelefonNr. ist bei Kunden nicht zwingend erforderlich
        if (e.value === null || e.value.toString().trim() === '') {
          this.validatePhoneNumber.next(true);
          break;
        } else {
          if (e.value.substr(0, 4) === '0049') {
            this.storageBoxOrder.gervm_extern_mobil_tel = '+49' + e.value.substr(4, 99);
          }
        }
        this.smsService.callPhoneNumberValidator(e.value).then(resSmsPhoneFormat => {
          if (!!resSmsPhoneFormat && resSmsPhoneFormat?.success === true) {
            this.isValidPhoneNumber = true;
            this.storageBoxOrder.gervm_extern_mobil_tel = resSmsPhoneFormat.international_formatted;
            this.getIsValidOrderData();
          } else {
            this.isValidPhoneNumber = false;
            this.getIsValidOrderData();
          }
        });
        break;
      case 'gervm_anz':
        if (e.value === null || e.value === 0) {
          this.storageBoxOrder.gervm_brutto_amount = 0;
          break;
        } else if (this.isLoading == false) {
          this.storageBoxOrder.gervm_brutto_amount = e.value * this.storageBoxService.getPreisProStorageBox();
        }
        break;
      case 'gervm_rgempf_adr_id':
        if (this.authenticationService.isEmployee && this.isLoading === false) {
          if (this.storageBoxOrder.gervm_ladestelle_adr_id === undefined || this.storageBoxOrder.gervm_ladestelle_adr_id === null || this.storageBoxOrder.gervm_ladestelle_adr_id.valueOf() < 1) {
            this.storageBoxOrder.gervm_ladestelle_adr_id = e.value;
          }
        }
        break;
    }
  }

  checkComparison() {
    return true;
  }

  getIsValidOrderData(): boolean {
    if (this.authenticationService.isAuthenticated && this.authenticationService.isEmployee
      && (this.storageBoxOrder.gervm_rgempf_adr_id === undefined || this.storageBoxOrder.gervm_rgempf_adr_id <= 0)) {
      this.adresseService.getDiversePortalKundenAdrId().then(res => {
        this.storageBoxOrder.gervm_rgempf_adr_id = res;
      });
    }
    this.isValidOrderData = this.storageboxForm.instance.validate().isValid;
    return this.storageboxForm.instance.validate().isValid;
  }

  async onSubmitOrderData() {
    if (!environment.production) console.log('onSubmitOrderData START before isValid ', this.storageBoxOrder);
    if (!environment.production) console.log('onSubmitOrderData ', this.storageBoxOrder.gervm_extern_mobiletan, this.storageBoxOrder);
    if (!this.getIsValidOrderData()) {
      notify({
        message: 'Bitte erst die Daten vervollständigen, eine TAN anfordern und eintragen.',
        width: this.notifyWidth,
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'error', 2000);
      this.isDisableEditingData = false;
      return;
    }

    if (!this.storageBoxOrder?.gervm_extern_mobiletan || this.storageBoxOrder.gervm_extern_mobiletan.toString().trim().length !== 6) {
      notify({
        message: 'Bitte erst die korrekte per SMS übermittelte TAN eintragen.',
        width: this.notifyWidth,
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'error', 2000);
      this.isDisableEditingData = false;
      return;
    }

    this.isDisableEditingData = true;
    this.isDisableEditingLagerBox = true;
    this.isProcessing = true;

    if (this.paymentType === paymentTypesEnum.payPal) {
      this.storageBoxOrder.gervm_bezahlt_provider_name = 'PayPal';
    }

    this.storageBoxService.create(this.storageBoxOrder).then(res => {
      this.isProcessing = false;
      if (!environment.production) console.log('submit after storageBoxService.create: ', res);
      if (res === undefined || res.status === undefined || res.status !== 'success') {
        notify({
          message: (res !== undefined && !!res?.message)
            ? res.message
            : 'Problem: Ihr Lagerbox-Auftrag konnte NICHT erfolgreich angelegt werden.',
          width: this.notifyWidth,
          position: {
            my: 'center',
            at: 'center'
          }
        }, 'error', 4000);
        this.isDisableEditingData = false;
        this.isProcessing = false;
        this.isValidOrderData = false;
        this.isMobileTanGenerated = true;
        res.status = undefined;
        res.message = undefined;
        return;
      }
      res.status = undefined;
      res.message = undefined;
      this.storageBoxOrder = res;

      if (this.storageBoxOrder.gervm_id && this.storageBoxOrder.gervm_id > 0) {
        if (this.paymentType === paymentTypesEnum.bankTransfer) {
          notify({
            message: 'Ihr Lagerbox-Auftrag wurde gebucht und wird nach erfolgter Zahlung gültig. ' +
              'Siehe Benachrichtigung per SMS und ggf. E-Mail.',
            width: this.notifyWidth,
            position: {
              my: 'center',
              at: 'center'
            }
          }, 'success', 4000);
          this.isProcessing = false;
          this.router.navigate(['/storagebox/order/show', this.storageBoxOrder.gervm_id, this.storageBoxOrder.gervm_extern_mobiletan]);

        } else if (this.paymentType === paymentTypesEnum.payPal) {
          this.isDisableEditingData = true;
          this.isProcessing = false;
          this.isPayPalButtonsDisabled = false;
          this.isSubmitOrderDataAndPayPalDisabled = true;
          this.scrollPageToBottom();

          notify({
            message: 'Jetzt bitte per PayPal bezahlen. ',
            width: this.notifyWidth,
            position: {
              my: 'center',
              at: 'center'
            }
          }, 'info', 4000);
        }
      }
    });
    return;
  }

  public getIsValidEndeDatzeit(e: any): boolean {
    switch (true) {
      case e.value === null || e.value.toString().trim() === '':
        this.isValidEndeDatzeit = true;
        break;
      case !this.storageBoxOrder.gervm_beginn_datzeit:
        this.isValidEndeDatzeit = true;
        break;
      case !!this.storageBoxOrder.gervm_beginn_datzeit
      && this.storageBoxOrder.gervm_beginn_datzeit >= new Date(e.value):
        this.isValidEndeDatzeit = false;
        this.notValidEndeDatzeitMessage = 'Enddatum muss größer als das Beginndatum sein';
        break;
      case !!this.storageBoxOrder.gervm_beginn_datzeit && new Date(e.value) > this.storageBoxService.calcRentalEndDate(this.storageBoxOrder.gervm_beginn_datzeit):
        this.isValidEndeDatzeit = false;
        this.notValidEndeDatzeitMessage = 'Enddatum darf die maximale Mietdauer nicht überschreiten: '
          + this.storageBoxService.calcRentalEndDate(this.storageBoxOrder.gervm_beginn_datzeit).toLocaleDateString('de-DE');
        break;
      default:
        this.isValidEndeDatzeit = true;
        break;
    }
    return this.isValidEndeDatzeit;
  }

  public getIsValidGervmGerNr(e: any): boolean {
    if (e.value === null || e.value.toString().trim() === '') {
      this.reloadStorageBoxDddw();
      this._isValidStorageBoxId = true;
      return true;
    } else {
      let firstEntryMatchIndex = -2;
      let newGerNr: number = Number(e.value);
      firstEntryMatchIndex = this.storageBoxDddw.findIndex((geraet: Geraet) => newGerNr == geraet.ger_nr);
      this._isValidStorageBoxId = firstEntryMatchIndex >= 0;
      return this._isValidStorageBoxId;
    }
  }

  /**
   * leere oder korrekte Telefonnummer
   * @param e
   */
  public getIsValidPhoneNumber(e: any): boolean {
    if (e.value === null || e.value.toString().trim() === '') {
      this.isValidPhoneNumber = true;
      return true;
    }
    return this.isValidPhoneNumber;
  }

  public isSubmitOrderDisabled(): boolean {
    return this.submitOrderDisabled.getValue();
  }

  public goBack(): void {
    this.location.back();
  }

  public printPreview(): void {
    if (!!this.storageBoxOrder.gervm_id && this.storageBoxOrder.gervm_id > 0
      && !!this.storageBoxOrder.gervm_extern_mobiletan && this.storageBoxOrder.gervm_extern_mobiletan?.valueOf() !== null) {
      this.router.navigate(['storagebox/order/show',
        this.storageBoxOrder.gervm_id,
        this.storageBoxOrder.gervm_extern_mobiletan?.valueOf()]);
    } else {
      window.print();
    }
  }

  getTan(personalKey: string): Promise<ITan> {
    if (this.storageBoxOrder.gervm_extern_mobiletan && this.storageBoxOrder.gervm_extern_mobiletan?.toString().trim().length === 6) {
      return new Promise<ITan>((resolve) => {
        const tan: ITan = {
          tan: this.storageBoxOrder.gervm_extern_mobiletan,
          tpol_id: this.storageBoxOrder.gervm_tpol_id
        };
        resolve(tan);
      });
    }
    return this.storageBoxService.getNewTan(personalKey).then((resTan: ITan) => {
      return resTan;
    });
  }

  public getDisableEditingLagerBox(): boolean {
    this.isDisableEditingLagerBox = this.storageBoxService.getDisableEditingLagerBox(this.storageBoxOrder) || this.isDisableEditingData;
    return this.isDisableEditingLagerBox;
  }

  popoverToggleDefault() {
    this.popoverVisible = !this.popoverVisible;
  }

  public getSaveButtonLabelText(): string {
    // console.log('getSaveButtonLabelText', this.storageBoxOrder, this.storageBoxOrder.gervm_id);
    if (this.storageBoxOrder === undefined || this.storageBoxOrder.gervm_id === undefined || this.storageBoxOrder.gervm_id <= 0) {
      return 'Lagerbox-Auftrag buchen';
    }
    return 'Lagerbox-Auftrag ändern & benachrichtigen';
  }

  getMaxCardWidth(): string {
    return this.maxCardWidth;
  }

  public reloadStorageBoxDddw() {
    if (!!this.storageBoxOrder.gervm_beginn_datzeit && !!this.storageBoxOrder.gervm_ende_datzeit) {
      let alwaysIncludeGerNrOfGervmId: number = null;
      if (!!this.storageBoxOrder.gervm_id) {
        alwaysIncludeGerNrOfGervmId = this.storageBoxOrder.gervm_id;
      }
      this.geraetService.getStorageStorageBoxDddwAvailableInRange(this.storageBoxOrder.gervm_beginn_datzeit, this.storageBoxOrder.gervm_ende_datzeit, alwaysIncludeGerNrOfGervmId)
        .subscribe(resStorageBox => {
            this.storageBoxDddw = resStorageBox;
          },
          error => {
            console.log('reloadStorageBoxData error', error);
            return;
          });
    } else {
      this.storageBoxDddw = null;
      this.isDisableEditingLagerBox = this.isDisableEditingData || false;
    }
    setTimeout(() => {
      this.storageboxForm.instance.getEditor('gervm_ger_nr').option('dataSource', this.storageBoxDddw);
    }, 1000);
  }

  public storageBoxDddwFocusIn(e: any): any {
    // console.log('storageBoxDddwFocusIn', e);
    return;
  }

  public paymentTypeChanged(e: any): any {
    if (!environment.production) console.log('paymentTypeChanged', e);
    if (e?.value === undefined) {
      this.isPaymentTypeSelected = false;
      this.isPayPalButtonsDisabled = true;
      this.isSubmitOrderDataAndCashInAdvanceDisabled = true;
      return;
    }
    this.paymentType = e.value;
    if (!environment.production) console.log('paymentTypeChanged this.paymentType', e.value, this.paymentType, this.paymentType == this.paymentTypeByPayPal);
    this.isPaymentTypeSelected = true;

    this.isPayPalButtonsDisabled = true
    this.isSubmitOrderDataAndPayPalDisabled = this.paymentType !== paymentTypesEnum.payPal;
    this.buttonSubmitOrderDataAndPayPal.disabled = this.paymentType !== paymentTypesEnum.payPal;

    this.isSubmitOrderDataAndCashInAdvanceDisabled = this.paymentType !== paymentTypesEnum.cash && this.paymentType !== paymentTypesEnum.bankTransfer;
    this.buttonSubmitOrderDataAndCashInAdvance.disabled = this.paymentType !== paymentTypesEnum.cash && this.paymentType !== paymentTypesEnum.bankTransfer;

    if (this.buttonGoToPayPal !== undefined && this.paymentType === paymentTypesEnum.payPal) {
      this.scrollPageToBottom();
    }
    return;
  }

  private timerEvent() {
    // this.reloadStorageBoxDddw();
  }
}
