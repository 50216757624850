  <div class="sits-container">
    <div class="sits-card-center sits-card sits-card-small">
      <div class="dx-fieldset">
        <div class="dx-field-item-content">
          <p *ngIf="isAuthenticated() && currentUser!==undefined">Benutzer: <b>{{currentUser.displayName}}</b>
            <span
              *ngIf="!!currentUser.companyName && currentUser.companyName !=='todo'"><br><b>{{currentUser.companyName}}</b></span><br>
            <span *ngIf="(currentUser.isEmployee===1 || currentUser.isCustomer===1)">
            Zugehörigkeiten...&nbsp;<b>
            <span *ngIf="currentUser.isEmployee===1">betriebszugehörig</span>
              <span *ngIf="currentUser.isEmployee===1 && currentUser.isCustomer===1">, </span>
              <span *ngIf="currentUser.isCustomer===1">Frachtkunde / Kunde / Geschäftspartner</span></b>
            <br>
          </span>
          </p>
        </div>

        <div class="sits-kachelbox">
          <div class="sits-kachel" *ngIf="isAuthenticated() && !!currentUser && (currentUser.isEmployee===1 || currentUser.isCustomer===1)">
            <a mat-button [routerLink]="'/frachtbrief/list'">
              <img src="assets/iconexperience/iconex_o2/o_collection_svg/delivery_note.svg"><br/>
              <div class="sits-kachel-text">Frachtbriefe</div>
            </a>
          </div>

          <div class="sits-kachel">
            <a mat-button [routerLink]="'/flugplan'">
              <img src="assets\images\airplane-2.svg"><br/>
              <div class="sits-kachel-text">Flugplan</div>
            </a>
          </div>

          <div class="sits-kachel">
            <a mat-button [routerLink]="'/fahrplan'">
              <img src="assets\images\db-siw-faehre2.png"><br/>
              <div class="sits-kachel-text">Fahrplan</div>
            </a>
          </div>

<!--          <div class="sits-kachel">
            <a mat-button [routerLink]="'/tarif/list/current'">
              <img src="assets/iconexperience/iconex_o2/o_collection_svg/invoice_euro.svg"><br/>
              <div class="sits-kachel-text">Frachttarife</div>
            </a>
          </div>-->

          <div class="sits-kachel" *ngIf="currentUser!==undefined && currentUser.isEmployee===1">
            <a mat-button [routerLink]="'/schedulertasks/list'">
              <img src="assets/iconexperience/iconex_o2/o_collection_svg/delivery_man.svg"><br/>
              <div class="sits-kachel-text">Aufgaben</div>
            </a>
          </div>

          <div class="sits-kachel" *ngIf="isAuthenticated()">
            <a mat-button [routerLink]="'/adresse/list'">
              <img src="assets/iconexperience/iconex_o2/o_collection_svg/user_earth.svg"><br/>
              <div class="sits-kachel-text">Adressen</div>
            </a>
          </div>

          <!--<div class="sits-kachel"
               id="gepaeckauftrag-cust-edit"
               *ngIf="currentUser!==undefined
               && (currentUser.isEmployee===1 || currentUser.isCustomer===1)">
            <a mat-button [routerLink]="'/gepaeckauftrag-cust/edit'">
              <img src="assets/iconexperience/iconex_o2/o_collection_svg/platform_truck_suitcase_business_people.svg"><br/>
              <div class="sits-kachel-text">Gepäckauftrag</div>
            </a>
            <dx-tooltip
              target="#gepaeckauftrag-cust-edit"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend">
              <div *dxTemplate="let data of 'content'">
                <p>Gepäckaufträge für registrierte Kunden.<br>Einfache Administration auch für Ihre Gäste.<br>Bezahlung per Sammelrechnung.</p>
              </div>
            </dx-tooltip>
          </div>-->

          <div class="sits-kachel"
               id="gepaeckauftrag-cust-list"
               *ngIf="currentUser!==undefined
               && (currentUser.isEmployee===1 || currentUser.isCustomer===1)">
            <a mat-button [routerLink]="'/gepaeckauftrag-cust/list'">
              <img src="assets\images\suitcase_db.svg"><br/>
              <div class="sits-kachel-text">Gepäck</div>
            </a>
            <dx-tooltip
              target="#gepaeckauftrag-cust-list"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend">
              <div *dxTemplate="let data of 'content'">
                <p>Gepäckaufträge für registrierte Kunden.<br>Einfacher und schneller Service für Sie und Ihre
                  Gäste.<br>
                  Bei erfolgreicher Buchung benachrichtigen wir Ihre Gäste und Sie per Mail.<br>Bezahlung bequem per
                  Sammelrechnung.</p>
              </div>
            </dx-tooltip>
          </div>

          <div class="sits-kachel"
               id="gepaeckauftrag-cust-list-zustellung"
               *ngIf="!environment.production && currentUser!==undefined && (currentUser?.isEmployee ?? 0) ===1">
            <a [routerLink]="['/gepaeckauftrag-cust/list/zustellen']" [queryParams]="getQueryParams('GEP-zustellen')" >
              <img src="assets\images\ankunft_platform_truck_suitcase_db.svg"><br/>
              <div class="sits-kachel-text">GEP zustellen</div>
            </a>
            <dx-tooltip
              target="#gepaeckauftrag-cust-list-zustellung"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend">
              <div *dxTemplate="let data of 'content'">
                <p>GEP Zustellung.<br>
                  Liste filtern nach Datum, Ankunftsort, Fähre.<br>
                  Sortiert nach Sort-Vorgabe oder Tour, Straße, Hausnummer<br>
                  <b>Zugestellte Aufträge als 'erledigt' kennzeichnen.</b></p>
              </div>
            </dx-tooltip>
          </div>

          <div class="sits-kachel"
               id="gepaeckauftrag-cust-list-abholung"
               *ngIf="currentUser!==undefined && (currentUser?.isEmployee ?? 0) ===1">
            <a [routerLink]="['/gepaeckauftrag-cust/list/abholen']" [queryParams]="getQueryParams('GEP-abholen')" >
              <img src="assets\images\abfahrt_platform_truck_suitcase_db.svg"><br/>
              <div class="sits-kachel-text">GEP abholen</div>
            </a>
            <dx-tooltip
                    target="#gepaeckauftrag-cust-list-abholung"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend">
              <div *dxTemplate="let data of 'content'">
                <p>GEP Abholung.<br>
                  Liste filtern nach Datum, Abfahrtsort, Fähre.<br>
                  Sortiert nach Sort-Vorgabe oder Tour, Straße, Hausnummer<br>
                  <b>Abgeholte Aufträge als 'erledigt' kennzeichnen.</b></p>
              </div>
            </dx-tooltip>
          </div>


          <div class="sits-kachel"
               id="gepaeckauftrag-edit"
               *ngIf="!(currentUser!==undefined
               && (currentUser.isEmployee===1 || currentUser.isCustomer===1))">
            <a mat-button [routerLink]="'/gepaeckauftrag/edit'">
              <!--<img src="assets/iconexperience/iconex_o2/o_collection_svg/platform_truck_suitcase.svg"><br/>-->
              <img src="assets\images\platform_truck_suitcase_db.svg"><br/>
              <div class="sits-kachel-text">Gepäck</div>
            </a>
            <dx-tooltip
              target="#gepaeckauftrag-edit"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend">
              <div *dxTemplate="let data of 'content'">
                <p>Wir bringen Ihr Gepäck ...<br>
                  ... bei der Anreise zur Unterkunft (Gepäck-Zustellung), <br>
                  ... bei der Abreise zur Fähre oder zum Flughafen.<br>
                  Einfach loslegen und Gepäckauftrag direkt anlegen,<br>
                  <b>ohne</b> Registrierung und Passwort.<br>
                  Bezahlung einfach per PayPal.<br>
                  Sie erhalten abschließend eine SMS und eine Mail mit den Buchungsdaten.</p>
              </div>
            </dx-tooltip>
          </div>

          <div class="sits-kachel" *ngIf="isAuthenticated() && !!currentUser && (currentUser.isEmployee===1 || currentUser.isCustomer===1)">
            <a mat-button [routerLink]="'/accounting/invoice/list'">
              <img src="assets/iconexperience/iconex_o2/o_collection_svg/invoice.svg"><br/>
              <div class="sits-kachel-text">Belegarchiv</div>
            </a>
          </div>

          <div class="sits-kachel" *ngIf="false && isAuthenticated() && !!currentUser && (currentUser.isEmployee===1 || currentUser.isCustomer===1)">
            <a mat-button [routerLink]="'/map'">
              <img src="assets/iconexperience/iconex_o2/o_collection_svg/map.svg"><br/>
              <div class="sits-kachel-text">Karte</div>
            </a>
          </div>

       <!--   <div class="sits-kachel" id="login" *ngIf="isAuthenticated()===false || currentUser===undefined">
            <a mat-button [routerLink]="['/login']">
              <img src="assets/iconexperience/iconex_o2/o_collection_svg/log_in.svg"><br/>
              <div class="sits-kachel-text">Login</div>
            </a>
            <dx-tooltip
              target="#login"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend">
              <div *dxTemplate="let data of 'content'">
                <p>Als Inselgast können Sie Gepäckaufträge auch <b>ohne</b> Login buchen.<br>
                  Registrierte Kunden können Fracht und Gepäck buchen,<br>
                  alte Vorgänge und Rechnungen sichten.<br>
                </p>
              </div>
            </dx-tooltip>
          </div>-->

          <div class="sits-kachel">
            <a mat-button [routerLink]="'/about'">
              <!--<img src="assets/iconexperience/iconex_o2/o_collection_svg/information.svg"><br/>-->
              <img src="assets\images\information_db.svg"><br/>
              <div class="sits-kachel-text">Impressum</div>
            </a>
          </div>

          <div class="sits-kachel">
            <a mat-button [routerLink]="'/datenschutz'">
              <!--<img src="assets/iconexperience/iconex_o2/o_collection_svg/safety_pin.svg"><br/>-->
              <img src="assets\images\safety_pin_db.svg"><br/>
              <div class="sits-kachel-text">Datenschutz</div>
            </a>
          </div>

          <div class="sits-kachel" *ngIf="false && isAuthenticated()">
            <!--todo-->
            <a mat-button [routerLink]="'/settings'">
              <img src="assets/iconexperience/iconex_o2/o_collection_svg/tools.svg"><br/>
              <div class="sits-kachel-text">Optionen</div>
            </a>
          </div>

          <div class="sits-kachel" *ngIf="isAuthenticated() && !!currentUser && !!this.currentUser?.id" [hidden]="isProductionEnvironment()">
            <a mat-button [routerLink]="['admin/user/edit', this.currentUser.id]"
               queryParamsHandling="merge">
              <img src="assets/iconexperience/iconex_o2/o_collection_svg/user.svg"><br/>
              <div class="sits-kachel-text">Meine Daten</div>
            </a>
          </div>

          <div class="sits-kachel" [hidden]="!isAuthenticated()">
            <a mat-button [routerLink]="'/changepassword'">
              <img src="assets/iconexperience/iconex_o2/o_collection_svg/log_changepassword.svg"><br/>
              <div class="sits-kachel-text-xs">Passwort ändern</div>
            </a>
          </div>

          <div class="sits-kachel" [hidden]="!isAuthenticated()">
            <a mat-button [routerLink]="['/logout']">
              <img src="assets/iconexperience/iconex_o2/o_collection_svg/power.svg"><br/>
              <div class="sits-kachel-text">Abmelden</div>
            </a>
          </div>
        </div>

        <div class="dx-field-empty-item"><br></div>
      </div>
    </div>
  </div>

